import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import CatalogServices from '../../services/general/catalogServices'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import MiniProductCard from './MiniProductCard';
import { useLangContext } from '../../i18n/ProvideLang';

const CatalogSidebarProducts = () => {
    const {localizationsItems} = useLangContext();

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(CatalogServices.products.index)

    const settings = {
        items: 1,
        margin: 20,
		lazyLoad: true,
		nav: true,
		navText: [ '<i class="icon-angle-left">', '<i class="icon-angle-right">' ],
		dots: false,
    }

  return (
    items?.items?.length >= 1 &&
    <div className="widget widget-featured">
        <h3 className="widget-title2">{localizationsItems?.favorites}</h3>
        <div className="widget-body">
            <OwlCarousel className="owl-carousel widget-featured-products" {...settings}>
                <div className="featured-col">
                    {items.items.slice(0,3).map((card, index) =>
                        <MiniProductCard card={card} key={index}/>
                    )}
                </div>
                <div className="featured-col">
                    {items.items.slice(3,6).map((card, index) =>
                        <MiniProductCard card={card} key={index}/>
                    )}
                </div>
            </OwlCarousel>
        </div>
    </div>
  )
}

export default CatalogSidebarProducts