import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'
import TabOrders from './TabOrders'
import TabAddress from './TabAddress'
import TabEdit from './TabEdit'
import TabBilling from './TabBilling'
import TabShipping from './TabShipping'
import { useAuth } from '../../pages/user/ProvideAuth'
import AuthServices from '../../services/general/authServices'

const DashboardContainer = () => {
    const {lang, localizationsItems} = useLangContext();
    const {removeToken, removeInfo} = useAuth();

    const navigate = useNavigate();

    const logOut = async () => {
        const res = await AuthServices.logOut();

        if (res.statusCode === 200) {
            removeToken();
            removeInfo();
            navigate(`/${lang}`);
        }
    }

  return (
    <div className="container account-container custom-account-container">
        <Tab.Container defaultActiveKey="tab-order">
            <div className="row">
                <div className="sidebar widget widget-dashboard mb-lg-0 mb-3 col-lg-3 order-0">
                    <h2 className="text-uppercase">{localizationsItems?.my_account}</h2>
                    <Nav className="nav nav-tabs list flex-column mb-0" role="tablist">
                        <Nav.Item>
                            <Nav.Link eventKey="tab-order">{localizationsItems?.orders}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="tab-address">{localizationsItems?.addresses}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="tab-edit">{localizationsItems?.account_detail}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="tab-shipping">{localizationsItems?.address_delivery}</Nav.Link>
                        </Nav.Item>
                        <div className='nav-item'>
                            <NavLink className="nav-link" to={`/${lang}/wishlist`}>{localizationsItems?.wishlist}</NavLink>
                        </div>
                        <div className='nav-item' onClick={logOut}>
                            <div className="nav-link">{localizationsItems?.logout}</div>
                        </div>
                    </Nav>
                </div>
                <Tab.Content className="col-lg-9 order-lg-last order-1 tab-content">
                    <Tab.Pane eventKey="tab-order">
                        <TabOrders/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab-address">
                        <TabAddress/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab-edit">
                        <TabEdit/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab-billing">
                        <TabBilling/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab-shipping">
                        <TabShipping/>
                    </Tab.Pane>
                </Tab.Content>
            </div>
        </Tab.Container>
    </div>
  )
}

export default DashboardContainer