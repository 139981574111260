import React from 'react'
import { Nav, Tab } from 'react-bootstrap'

const TabProduct = ({item}) => {
  return (
    <Tab.Container className="product-single-tabs" defaultActiveKey="tab-description0">
        <Nav className="nav nav-tabs" role="tablist">
            {item?.map((card, index) =>
                <Nav.Item className="nav-item" key={index}>
                    <Nav.Link className="nav-link" eventKey={`tab-description${index}`}>{card.title}</Nav.Link>
                </Nav.Item>
            )}
        </Nav>

        <Tab.Content className="tab-content">
            {item?.map((card, index) =>
                <Tab.Pane className="tab-pane fade" eventKey={`tab-description${index}`} key={index}>
                    <div className="product-desc-content" dangerouslySetInnerHTML={{__html: card.description}}></div>
                </Tab.Pane>
            )}
        </Tab.Content>
    </Tab.Container>
  )
}

export default TabProduct