import React from 'react'
import Utils from '../../services/utils'
import { useLangContext } from '../../i18n/ProvideLang';
import { NavLink } from 'react-router-dom';

const MiniProductCard = ({card}) => {
    const {lang, localizationsItems} = useLangContext();

  return (
    <div className="product-default left-details product-widget mini-product">
        <figure>
            <NavLink to={`/${lang}/products/${card.slug}`}>
                <img src={'https://solution-0003.panama.kz' + card.preview_image} width="75" height="75" alt="product" />
                {card.detail_image && <img src={'https://solution-0003.panama.kz' + card.detail_image} width="75" height="75" alt="product" />}
            </NavLink>
        </figure>
        <div className="product-details">
            <h3 className="product-title"> <NavLink to={`/${lang}/products/${card.slug}`}>{card.title}</NavLink> </h3>
            <div className="price-box">
                <span className="product-price">{Utils.numberWithSpaces(card.price)} {localizationsItems?.currency}</span>
            </div>
        </div>
    </div>
  )
}

export default MiniProductCard