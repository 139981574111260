import React from 'react'
import { Modal } from 'react-bootstrap';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import CatalogServices from '../../services/general/catalogServices';
import { useLangContext } from '../../i18n/ProvideLang';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ProductSlider from '../simple/ProductSlider';
import AddToCart from '../complex/AddToCart';
import ShareBlock from '../simple/ShareBlock';
import Utils from '../../services/utils';

const QuickViewModal = ({show, setShow, slug}) => {
    const handleClose = () => setShow(false);
    const {localizationsItems, lang} = useLangContext();

    const {info, loading} = useFetchInfo(CatalogServices.products.view, slug);

  return (
    <Modal show={show} onHide={handleClose} centered className='product-single-container'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {info &&
            <div className="row">
                <div className="col-md-6">
                    <ProductSlider slider={info.gallery}/>
                </div>
                <div className="col-md-6">
                    <div className="product-single-details mb-0 ml-md-4">
                        <h1 className="product-title">{info.title}</h1>
                        <hr className="short-divider"/>

                        <div className="price-box">
                            {info.old_price && <span className="old-price">{Utils.numberWithSpaces(info.old_price)} {localizationsItems?.currency}</span>}
                            <span className="product-price">{Utils.numberWithSpaces(info.price)} {localizationsItems?.currency}</span>
                        </div>
                        <div className="product-desc">
                            <p>
                                {info.subtitle}
                            </p>
                        </div>
                        {info.features &&
                        <ul className="single-info-list">
                            {info.features.map((el, index) => 
                                <li key={index}>
                                    {el.title}:
                                    <strong>{el.value}</strong>
                                </li>
                            )}
                        </ul>}

                        <AddToCart card={info}/>

                        <div className="product-single-share mb-0">
                            <ShareBlock/>
                            <a href="wishlist.html" className="btn-icon-wish add-wishlist" title="Add to Wishlist">
                                <i className="icon-wishlist-2"></i><span>{localizationsItems?.add_to_wishlist}</span></a>
                        </div>
                    </div>
                </div>
            </div>}
        </Modal.Body>
    </Modal>
  )
}

export default QuickViewModal