import React from 'react'
import ProductCard from '../simple/ProductCard'
import PageTopBanner from '../simple/PageTopBanner'
import PageTopNav from '../simple/PageTopNav'
import ProductsCategory from '../simple/ProductsCategory'
import ProductCategoryTop from '../simple/ProductCategoryTop'
import Pagination from '../simple/Pagination'
import useFetchItems from '../../hooks/useFetchItems'
import CatalogServices from '../../services/general/catalogServices'
import { useLangContext } from '../../i18n/ProvideLang'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import CatalogSidebarProducts from '../simple/CatalogSidebarProducts'

const ProductsContainer = ({item}) => {
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();
    let query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/catalog`)  
            }
        }
    }, [lang])

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(CatalogServices.products.index)

    useEffect(() => {
        if(query.get('categories[]')){
            setFilterData(query.toString())
        }
    }, [query.get('categories[]')])
    
    useEffect(() => {
        if(query.get('term')){
            setFilterData(query.toString())
        }
    }, [query.get('term')])

  return (
    <main className="main">
        <PageTopBanner item={item}/>
        <div className="container">
            <PageTopNav/>
            <div className="row">
                <div className="col-lg-9 main-content">
                    <div className="row products-group">
                        {items?.items?.map((card, index) =>
                            <div className="col-6 col-sm-4" key={index}>
                                <ProductCard card={card}/>
                            </div>
                        )}
                    </div>
                    <nav className="toolbox toolbox-pagination">
                        <div className="toolbox-item toolbox-show"></div>
                        <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                    </nav>
                </div>
                <div className="sidebar-overlay"></div>
                <aside className="sidebar-shop col-lg-3 order-lg-first mobile-sidebar">
                    <div className="sidebar-wrapper">
                        <ProductsCategory setFilterData={setFilterData}/>
                        <CatalogSidebarProducts/>
                    </div>
                </aside>
            </div>
        </div>
        <div className="mb-4"></div>
    </main>
  )
}

export default ProductsContainer