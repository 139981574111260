import ArticleDetail from "../pages/articles/ArticleDetail";
import ArticlesPage from "../pages/articles/ArticlesPage";
import Cart from "../pages/cart/Cart";
import Checkout from "../pages/cart/Checkout";
import OrderSuccess from "../pages/cart/OrderSuccess";
import WishList from "../pages/cart/WishList";
import Error from "../pages/error/Error";
import EventDetail from "../pages/events/EventDetail";
import EventsPage from "../pages/events/EventsPage";
import MainPage from "../pages/main/MainPage";
import ProductDetail from "../pages/products/ProductDetail";
import ProductsPage from "../pages/products/ProductsPage";
import ProjectDetail from "../pages/projects/ProjectDetail";
import ProjectsPage from "../pages/projects/ProjectsPage";
import SamplePage from "../pages/sample/SamplePage";
import ServiceDetail from "../pages/services/ServiceDetail";
import ServicesPage from "../pages/services/ServicesPage";
import Dashboard from "../pages/user/Dashboard";
import Login from "../pages/user/Login";
import Registration from "../pages/user/Registration";
import { BASKET_PAGE_ROUTE, BLOG_DETAIL_PAGE_ROUTE, BLOG_PAGE_ROUTE, CATALOG_PAGE_ROUTE, CHECKOUT_PAGE_ROUTE, DASHBOARD_PAGE_ROUTE, ERROR_PAGE_ROUTE, EVENTS_DETAIL_PAGE_ROUTE, EVENTS_PAGE_ROUTE, EVENTS_ROUTE, FAQ_PAGE_ROUTE, LOGIN_PAGE_ROUTE, MAIN_PAGE_ROUTE, ORDER_SUCCESS_PAGE_ROUTE, PRODUCTS_DETAIL_PAGE_ROUTE, PRODUCTS_PAGE_ROUTE, PROJECTS_DETAIL_PAGE_ROUTE, PROJECTS_PAGE_ROUTE, REGISTRATION_PAGE_ROUTE, SAMPLE_PAGE_SOUTE, SERVICES_DETAIL_PAGE_ROUTE, SERVICES_PAGE_ROUTE, USER_PAGE_ROUTE, WISHLIST_PAGE_ROUTE} from "./Constants";

export const publicRoutes = [
    {
        path: '/',
        Component: MainPage
    },
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    {
        path: BLOG_PAGE_ROUTE,
        Component: ArticlesPage
    },
    {
        path: BLOG_DETAIL_PAGE_ROUTE,
        Component: ArticleDetail
    },
    {
        path: PRODUCTS_PAGE_ROUTE,
        Component: ProductsPage
    },
    {
        path: PRODUCTS_DETAIL_PAGE_ROUTE,
        Component: ProductDetail
    },
    {
        path: SERVICES_PAGE_ROUTE,
        Component: ServicesPage
    },
    {
        path: SERVICES_DETAIL_PAGE_ROUTE,
        Component: ServiceDetail
    },
    {
        path: SAMPLE_PAGE_SOUTE,
        Component: SamplePage
    },
    {
        path: PROJECTS_PAGE_ROUTE,
        Component: ProjectsPage
    },
    {
        path: PROJECTS_DETAIL_PAGE_ROUTE,
        Component: ProjectDetail
    },
    {
        path: EVENTS_PAGE_ROUTE,
        Component: EventsPage
    },
    {
        path: EVENTS_DETAIL_PAGE_ROUTE,
        Component: EventDetail
    },
    {
        path: BASKET_PAGE_ROUTE,
        Component: Cart
    },
    {
        path: CHECKOUT_PAGE_ROUTE,
        Component: Checkout
    },
    {
        path: ORDER_SUCCESS_PAGE_ROUTE,
        Component: OrderSuccess
    },
    {
        path: ERROR_PAGE_ROUTE,
        Component: Error
    },
    {
        path: WISHLIST_PAGE_ROUTE,
        Component: WishList
    },
    {
        path: LOGIN_PAGE_ROUTE,
        Component: Login
    },
    {
        path: REGISTRATION_PAGE_ROUTE,
        Component: Registration
    },
    {
        path: DASHBOARD_PAGE_ROUTE,
        Component: Dashboard
    }
]