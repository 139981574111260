import React from 'react'

const TabShipping = () => {
  return (
    <div className="address account-content mt-0 pt-2">
        <h4 className="title mb-3">Адрес доставки</h4>

        <form className="mb-2" action="#">
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Имя <span className="required">*</span></label>
                        <input type="text" className="form-control" required />
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label>Фамилия <span className="required">*</span></label>
                        <input type="text" className="form-control" required />
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label>Компания </label>
                <input type="text" className="form-control"/>
            </div>

            <div className="select-custom">
                <label>Страна / регион <span className="required">*</span></label>
                <select name="orderby" className="form-control">
                    <option value="" selected="selected">Британская территория в Индийском океане
                    </option>
                    <option value="1">Бруней</option>
                    <option value="2">Болгария</option>
                    <option value="3">Буркина-Фасо</option>
                    <option value="4">Бурунди</option>
                    <option value="5">Камерун</option>
                </select>
            </div>

            <div className="form-group">
                <label>Адрес улицы <span className="required">*</span></label>
                <input type="text" className="form-control"
                    placeholder="House number and street name" required />
                <input type="text" className="form-control"
                    placeholder="Apartment, suite, unit, etc. (optional)" required />
            </div>

            <div className="form-group">
                <label>Городок / город <span className="required">*</span></label>
                <input type="text" className="form-control" required />
            </div>

            <div className="form-group">
                <label>Штат / страна <span className="required">*</span></label>
                <input type="text" className="form-control" required />
            </div>

            <div className="form-group">
                <label>Почтовый индекс / ZIP <span className="required">*</span></label>
                <input type="text" className="form-control" required />
            </div>

            <div className="form-footer mb-0">
                <div className="form-footer-right">
                    <button type="submit" className="btn btn-dark py-4">
                        Сохранить адрес
                    </button>
                </div>
            </div>
        </form>
    </div>
  )
}

export default TabShipping