import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import CatalogServices from '../../services/general/catalogServices'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ProductCard from './ProductCard';
import { useLangContext } from '../../i18n/ProvideLang';

const SimilarProducts = ({ids}) => {
    const {localizationsItems} = useLangContext();

    const {
        items,
    } = useFetchItems(CatalogServices.products.index, ids)

    const settings = {
        loop: false,
        margin: 20,
        autoplay: false,
        dots: true,
        items: 2,
        responsive: {
            576: {
                items: 3
            },
            992: {
                items: 4,
            }
        }
    }

  return (
    items?.items?.length >= 1 &&
    <div>
        <div className="products-section pt-0">
            <h2 className="section-title">{localizationsItems?.similar_products}</h2>

            <OwlCarousel className="products-slider owl-carousel owl-theme dots-top dots-small" {...settings}>
                {items.items.map((card, index) =>
                    <ProductCard card={card} key={index}/>
                )}
            </OwlCarousel>
        </div>
        <hr className="mt-0 m-b-5" />
    </div>
  )
}

export default SimilarProducts