import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from 'react-router-dom';
import img from "../../images/placeholder.png";

const AdvertisingBannerAnimation = ({elem}) => {
    const settings = {
        items: 1,
        dots: false,
        nav: true,
        loop: false,
        animate: 'fadeInLeftShorter',
    }

  return (
    <section className="hover-section mt-8 banners-page">
        <h3 className="text-center">{elem.title}</h3>
        <p className="text-center mx-auto mb-3"
            dangerouslySetInnerHTML={{__html: elem.description}}
        ></p>
        {elem.elements &&
            <OwlCarousel {...settings} className="owl-carousel owl-theme show-nav-hover slide-animate">
                {elem.elements.map((card, index) =>
                    <div className="banner banner3" key={index}>
                        <figure>
                            <img width="1920" height="700" src={card.image ? 'https://solution-0003.panama.kz' + card.image : img} alt="banner" 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img;
                                }}
                            />
                            <div className={index%2 === 0 ? "snowfall particle-effect" : "particle-effect sparkle"}></div>
                        </figure>
                        <div className={index%2 === 0 ? "banner-layer banner-layer-middle" : "banner-layer banner-layer-middle text-right"}>
                            <div className="appear-animate" data-animation-name="fadeInRightShorter">
                                {card.subtitle_top && <h4 className="banner-subtitle">{card.subtitle_top}</h4>}
                                {card.title && <h3 className="banner-title">{card.title}</h3>}
                                <p>{card.description}</p>
                                {card.link && <NavLink to={card.link} className="btn btn-dark" role="button">{card.subtitle}</NavLink>}
                            </div>
                        </div>
                    </div>
                )}
            </OwlCarousel>
        }
    </section>
  )
}

export default AdvertisingBannerAnimation