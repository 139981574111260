import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import AuthServices from '../../services/general/authServices';
import { useAuth } from './ProvideAuth';
import { NavLink, useNavigate } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import SendCodeModal from '../../components/modals/SendCodeModal';
import ResetPasswordModal from '../../components/modals/ResetPasswordModal';

const Login = () => {
    const {setToken, setInfo} = useAuth();
    const [openModal, setOpenModal] = useState();
    const [resetPasswordModal, setResetPasswordModal] = useState();

    const [email, setEmail] = useState();
    
    const navigate = useNavigate();

    const {lang, localizationsItems} = useLangContext();

    const {handleSubmit, register, getValues, setValue, control, formState: {errors}} = useForm();

	const onSubmit = async (data) => {
        const res = await AuthServices.logIn(data);

        if (res.statusCode === 200) {
            setInfo(res.content);
            setToken(res.content.token);
			navigate(`/${lang}`);
        }
    }

  return (
    <div className="container login-container">
        <div className="row">
            <div className="col-lg-12 mt-5 mb-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="heading mb-1">
                            <h2 className="title">{localizationsItems?.login}</h2>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label>{localizationsItems?.login_name}
                                <span className="required">*</span>
                            </label>
                            <input {...register('email')} type="email" className="form-input form-wide" id="login-email" required />

                            <label>{localizationsItems?.login_password}
                                <span className="required">*</span>
                            </label>
                            <input {...register('password')} type="password" className="form-input form-wide" id="login-password" required />

                            <div className="form-footer">
                                <NavLink to={`/${lang}/registration`} className="forget-password text-dark">{localizationsItems?.registration}</NavLink>
                                <div className="forget-password text-dark form-footer-right" onClick={() => setOpenModal(true)}>{localizationsItems?.forgot_password}</div>
                            </div>
                            <button type="submit" className="btn btn-dark btn-md w-100">
                                {localizationsItems?.auth}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        {openModal && <SendCodeModal show={openModal} setShow={setOpenModal} setEmail={setEmail} setResetPasswordModal={setResetPasswordModal}/>}
        {resetPasswordModal && <ResetPasswordModal show={resetPasswordModal} setShow={setResetPasswordModal} email={email}/>}
    </div>
  )
}

export default Login