import React from 'react'

const Advantages = ({elem}) => {
  return (
    <div className="container mt-2">
        <div className="info-boxes-container row row-joined mb-1 appear-animate" data-animation-name="fadeInUpShorter" data-animation-duration="1000">
            {elem?.elements?.map((card, index) =>
                <div className="info-box info-box-icon-left col-lg-4" key={index}>
                    <img src={'https://solution-0003.panama.kz' + card.image} alt="" />
                    <div className="info-box-content">
                        <h4>{card.title}</h4>
                        <p className="text-body" dangerouslySetInnerHTML={{__html: card.description}}></p>
                    </div>
                </div>
            )}
        </div>
    </div>
  )
}

export default Advantages