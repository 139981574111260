import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CatalogServices from '../../services/general/catalogServices';
import useFetchItems from '../../hooks/useFetchItems';
import ProductCard from '../simple/ProductCard';

const ProductsSlider = ({elem}) => {

    const {
        items,
        loading,
        params, 
        setFilterData
    } = useFetchItems(CatalogServices.products.index, null, `type=${elem.products_type}&`)

    const settings = {
        loop: false,
        margin: 20,
        autoplay: false,
        dots: true,
        items: 2,
        responsive: {
            576: {
                items: 3
            },
            992: {
                items: 4,
            }
        }
    }
    
  return (
    <div className="products-section section-bg-gray">
        <div className="container">
            <h2 className="section-title appear-animate" data-animation-name="fadeInUpShorter" data-animation-delay="200" data-animation-duration="1000">{elem.title}</h2>
            {items?.items?.length >= 1 &&
                <OwlCarousel className="products-slider owl-carousel owl-theme dots-top dots-small" {...settings}>
                    {items.items.map((card, index) =>
                        <ProductCard card={card} key={index}/>
                    )}
                </OwlCarousel>
            }
        </div>
    </div>
  )
}

export default ProductsSlider