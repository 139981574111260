import React from 'react'

const TabAddress = () => {
  return (
    <>
        <h3 className="account-sub-title d-none d-md-block mb-1">
            <i className="sicon-location-pin align-middle mr-3"></i>Адресы</h3>
        <div className="addresses-content">
            <p className="mb-4">
                Следующие адреса будут использоваться на странице оформления заказа
                по умолчанию.
            </p>

            <div className="row">
                <div className="address col-md-6">
                    <div className="heading d-flex">
                        <h4 className="text-dark mb-0">Платежный адрес</h4>
                    </div>

                    <div className="address-box">
                        Вы еще не настроили этот тип адреса.
                    </div>

                    <a href="#billing" className="btn btn-default address-action link-to-tab">Добавить
                        адрес</a>
                </div>

                <div className="address col-md-6 mt-5 mt-md-0">
                    <div className="heading d-flex">
                        <h4 className="text-dark mb-0">
                            Адрес доставки
                        </h4>
                    </div>

                    <div className="address-box">
                        Вы еще не настроили этот тип адреса.
                    </div>

                    <a href="#shipping" className="btn btn-default address-action link-to-tab">Добавить
                        адрес</a>
                </div>
            </div>
        </div>
    </>
  )
}

export default TabAddress