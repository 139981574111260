import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import useUid from '../../hooks/useUid.hook';
import { useBasketContext } from '../../hooks/ProvideBasket';
import CatalogServices from '../../services/general/catalogServices';
import { NavLink } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import CheckoutModal from '../modals/CheckoutModal';

const AddToCart = ({card}) => {
    const [count, setCount] = useState(1);
    const [visible, setVisible] = useState(false);
    const [checkoutModal, setCheckoutModal] = useState(false);

    const {uid, setUid} = useUid();

    const {localizationsItems, lang} = useLangContext();

    const {changeCountBasket} = useBasketContext();

    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const changeCount = (e) => {
        setCount(e.target.value)
    }

    const plusCount = () => {
        setCount(count+1)
    }

    const minusCount = () => {
        if(count > 1){
            setCount(count-1)
        }
    }

    const addToBasket = async(data) => {
        setVisible(true);

        let newData = {
            uid: uid ? uid : null,
            product_id: card.id,
            count: count,
            fields: []
        }

        for(var key in data){
            newData.fields.push(
                {name: key, value: data[key]}
            )
        }
          
        const res = await CatalogServices.cart.addToCart(newData);

        if (res.statusCode === 200) {
            if(!uid){
                setUid(res.content.uid)
            }
            changeCountBasket(Math.random().toString(16))
        }

        setTimeout(() => {
            setVisible(false)
        }, 3000);
    }

  return (
    <>
        <form onSubmit={handleSubmit(addToBasket)}>
            {card.traits?.map((it, index) => 
                <div className="product-filters-container" key={index}>
                    <div className="product-single-filter">
                        <label>{it.title}</label>
                        <div className="input-radio-label">
                            <div className="input-radio-label__list">
                                {it.elements?.map((el, index) =>
                                    <label key={index}>
                                        <input type="radio" {...register(it.title)} value={el.name}/>
                                        <span>{el.name}</span>
                                    </label>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="product-action">
                <div className="product-single-qty">
                    <input className="horizontal-quantity form-control" type="number" value={count} onChange={e => changeCount(e)}/>
                    <div className="input-number__add" onClick={plusCount}></div>
                    <div className="input-number__sub" onClick={minusCount}></div>
                </div>
                <button className="btn btn-dark add-cart mr-2 added-to-cart" title="Add to Cart">{localizationsItems?.add_to_cart}</button>

                <div className="btn btn-gray view-cart" onClick={() => setCheckoutModal(true)}>{localizationsItems?.checkout}</div>
            </div>
        </form>
        <hr className="divider mb-0 mt-0"/>
        {visible && <div className="cart-message ">
            <span>{localizationsItems?.product_added_cart}</span>
        </div>}
        {checkoutModal && <CheckoutModal show={checkoutModal} setShow={setCheckoutModal} productName={card?.name}/>}
    </>
  )
}

export default AddToCart