import React, { Suspense } from 'react'
import './i18n/i18n'
import ProvideLang from "./i18n/ProvideLang";
import ScrollToTop from './hooks/scrollToTop';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './navigation/AppRouter';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import ProvideBasket from './hooks/ProvideBasket';
import { ProvideAuth } from './pages/user/ProvideAuth';

const App = () => {
  return (
    <Suspense fallback={null}>
        <ProvideLang>
            <ProvideAuth>
                <ProvideBasket>
                    <BrowserRouter>
                        <ScrollToTop />
                        <AppRouter/>
                    </BrowserRouter>
                </ProvideBasket>
            </ProvideAuth>
        </ProvideLang>
    </Suspense>
  )
}

export default App