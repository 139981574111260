import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import useFetchItems from '../../hooks/useFetchItems';
import PartnersServices from '../../services/general/partnersServices';
import img from "../../images/placeholder.png";

const Partners = ({elem}) => {
    const {
        items
    } = useFetchItems(PartnersServices.index)

    const settings = {
        loop: false,
        margin: 20,
        autoplay: false,
        dots: false,
        navText: [ '<i class="icon-left-open-big">', '<i class="icon-right-open-big">' ],
        items: 2,
        responsive: {
            0: {
                items: 2
            },
            480: {
                items: 3
            },
            768: {
                items: 4
            },
            991: {
                items: 5
            },
            1200: {
                items: 6
            }
        }
    }

  return (
    items?.items?.length >= 1 &&
    <div className="brands-section mt-5 mb-5 appear-animate" data-animation-delay="200" data-animation-name="fadeIn" data-animation-duration="1000">
        <div className="container">
            <OwlCarousel {...settings} className="brands-slider owl-carousel owl-theme images-center">
                {items.items.map((card, index) =>
                    <a href={card.link} key={index} target='_blank'>
                        <img src={card.image ? 'https://solution-0003.panama.kz' + card.image : img} alt="brand"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img;
                            }}
                        />
                    </a>
                )}
            </OwlCarousel>
        </div>
    </div>
  )
}

export default Partners