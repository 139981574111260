import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from 'react-router-dom';
import ProductCategories from '../simple/ProductCategories';
import img from "../../images/placeholder.png";

const MainBanner = ({elem}) => {
    const settings = {
        items: 1,
        lazyLoad: false,
        autoplay: false,
        dots: true,
        nav: false,
        autoplayTimeout: 12000,
        animateOut: 'fadeOut',
        navText: [ '<i class="icon-left-open-big">', '<i class="icon-right-open-big">' ],
        loop: true
    }
    
  return (
    <div className="container mt-2">
        <div className="row">
            <ProductCategories/>
            {elem?.elements &&
                <div className="col-lg-9 mb-2 appear-animate" data-animation-name="fadeInLeftShorter" data-animation-delay="400" data-animation-duration="1000">
                    <OwlCarousel {...settings} className="home-slider owl-carousel owl-theme loaded">
                        {elem.elements.map((card, index) =>
                            <div className="home-slide home-slide2 banner banner-md-vw banner-sm-vw d-flex align-items-center" key={index}>
                                <img className="slide-bg" src={card.front_image ? 'https://solution-0003.panama.kz' + card.front_image : img} width="835" height="415" alt="slider image"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img;
                                    }}
                                />
                                <div className="banner-layer text-uppercase">
                                    <div dangerouslySetInnerHTML={{__html: card.description}}></div>
                                    <h2 dangerouslySetInnerHTML={{__html: card.title}} style={{color: card.banner_font_color}}></h2>
                                    {card.link && <NavLink to={card.link} className="btn btn-dark ls-10">{card.button_text}</NavLink>}
                                </div>
                            </div>
                        )}
                    </OwlCarousel>
                </div>
            }
        </div>
    </div>
  )
}

export default MainBanner