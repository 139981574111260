import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

const Error = () => {
    const {lang, localizationsItems} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/error`)
            }
        }
    }, [lang])

  return (
    <div className="block">
        <div className="container">
            <div className="not-found">
                <div className="not-found__404">
                    {localizationsItems?.error_title}
                </div>
                <div className="not-found__content">
                    <h1 className="not-found__title">{localizationsItems?.error_not_fount}</h1>
                    <p className="not-found__text">
                        {localizationsItems?.error_content}
                    </p>
                    <NavLink className="btn btn-secondary btn-sm mb-3" to={`/${lang}`}>{localizationsItems?.to_main}</NavLink>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Error