import React, { useState } from 'react'
import Utils from '../../services/utils'
import { useLangContext } from '../../i18n/ProvideLang';
import QuickViewModal from '../modals/QuickViewModal';
import { NavLink } from 'react-router-dom';
import ToCartModal from '../modals/ToCartModal';
import img from "../../images/placeholder.png"

const ProductCard = ({card}) => {
    const [show, setShow] = useState(false);
    const [toCartModal, setToCartModal] = useState(false);

    const {lang, localizationsItems} = useLangContext();

  return (
    <div className="product-default inner-quickview inner-icon">
        <figure className="img-effect">
            <NavLink to={`/${lang}/products/${card.slug}`}>
                <img src={card.preview_image ? 'https://solution-0003.panama.kz' +card.preview_image : img} width="205" height="205" alt="product"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                />
                {card.detail_image && <img src={card.detail_image ? 'https://solution-0003.panama.kz' + card.detail_image : img} width="205" height="205" alt="product"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                />}
            </NavLink>
            <div className="btn-icon-group">
                <button className="btn-icon btn-add-cart product-type-simple" onClick={() => setToCartModal(true)}><i className="icon-shopping-cart"></i></button>
            </div>
            <button className="btn-quickview" title="Quick View" onClick={() => setShow(true)}>{localizationsItems?.quick_view}</button>
        </figure>
        <div className="product-details">
            <div className="category-wrap">
                {card.parent && <div className="category-list">
                    <NavLink to={`/${lang}/catalog?categories=${card.parent.title}`} className="product-category">{card.parent.title}</NavLink>
                </div>}
                <a href="wishlist.html" title="Wishlist" className="btn-icon-wish"><i className="icon-heart"></i></a>
            </div>
            <h3 className="product-title">
                <NavLink to={`/${lang}/products/${card.slug}`}>{card.title}</NavLink>
            </h3>
            <div className="price-box">
                {card.old_price && <span className="old-price">{Utils.numberWithSpaces(card.old_price)} {localizationsItems?.currency}</span>}
                <span className="product-price">{Utils.numberWithSpaces(card.price)} {localizationsItems?.currency}</span>
            </div>
        </div>
        {show && <QuickViewModal show={show} setShow={setShow} slug={card?.slug}/>}
        {toCartModal && <ToCartModal show={toCartModal} setShow={setToCartModal} slug={card?.slug}/>}
    </div>
  )
}

export default ProductCard