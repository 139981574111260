import React from 'react'

const WishList = () => {
  return (
    <div className="container">
        <div className="wishlist-title">
            <h2 className="p-2">Мой список желаний на Porto Магазин 4</h2>
        </div>
        <div className="wishlist-table-container">
            <table className="table table-wishlist mb-0">
                <thead>
                    <tr>
                        <th className="thumbnail-col"></th>
                        <th className="product-col">Товар</th>
                        <th className="price-col">Цена</th>
                        <th className="status-col">Статус запаса</th>
                        <th className="action-col">Действия</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="product-row">
                        <td>
                            <figure className="product-image-container">
                                <a href="product.html" className="product-image">
                                    <img src="assets/images/products/product-4.jpg" alt="product"/>
                                </a>

                                <a href="#" className="btn-remove icon-cancel" title="Remove Product"></a>
                            </figure>
                        </td>
                        <td>
                            <h5 className="product-title">
                                <a href="product.html">Мужские часы</a>
                            </h5>
                        </td>
                        <td className="price-box">$17.90</td>
                        <td>
                            <span className="stock-status">В наличии</span>
                        </td>
                        <td className="action">
                            <a href="ajax/product-quick-view.html" className="btn btn-quickview btn-sm mt-1 mt-md-0"
                                title="Quick View">Быстрый
                                просмотр</a>
                            <button className="btn btn-dark btn-add-cart btn-sm product-type-simple btn-shop">
                                ДОБАВИТЬ В КОРЗИНУ
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default WishList