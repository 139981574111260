import React from 'react'
import EventsServices from '../../services/general/eventsServices'
import useFetchItems from '../../hooks/useFetchItems'
import EventCard from '../simple/EventCard'
import Pagination from '../simple/Pagination'

const EventsContainer = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(EventsServices.index)

  return (
    items &&
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="blog-section row">
                    {items.items?.map((card, index) =>
                        <EventCard card={card} key={index}/>
                    )}
                </div>
                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
            </div>
        </div>
    </div>
  )
}

export default EventsContainer