import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import CatalogServices from '../../services/general/catalogServices';
import { NavLink } from 'react-router-dom';
import Utils from '../../services/utils';
import img from "../../images/placeholder.png";

const DropdownCartProduct = ({product, uid, fetchItems}) => {
    const {localizationsItems, lang} = useLangContext();

    const deleteItem = async () => {
        const data = {
            uid: uid,
            product_id: product.id
        }
        
        const res = await CatalogServices.cart.remove(data);

        if(res.statusCode === 200){
            fetchItems()
        }
    }

  return (
    <div className="product">
        <div className="product-details">
            <h4 className="product-title">
                <NavLink to={`/${lang}/products/${product.slug}`}>{product.title}</NavLink>
            </h4>

            <span className="cart-product-info">
                <span className="cart-product-qty">{product.count}</span> × {Utils.numberWithSpaces(product.price)} {localizationsItems?.currency}
            </span>
        </div>
        <figure className="product-image-container">
            <NavLink to={`/${lang}/products/${product.slug}`} className="product-image">
                <img src={product.image ? 'https://solution-0003.panama.kz' + product.image : img} alt="product" width="80" height="80"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img;
                    }}
                />
            </NavLink>

            <div className="btn-remove" title="Remove Product" onClick={deleteItem}><span>×</span></div>
        </figure>
    </div>
  )
}

export default DropdownCartProduct