import React, { useEffect, useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import { NavLink } from 'react-router-dom';

const Footer = ({item}) => {
    const [socials, setSocials] = useState();
    const [email, setEmail] = useState();
    const [address, setAddress] = useState();
    const [phone, setPhone] = useState();
    const [text, setText] = useState();
    const [workTime, setWorkTime] = useState();
    const [widgets, setWidgets] = useState();

    const {localizationsItems} = useLangContext();

    const { 
        items,
    } = useFetchItems(AppServices.menu, "bottom-menu");

    useEffect(() => {
        if(item){
            setSocials(item.find(it => it.type === "socials")?.value)
            setEmail(item.find(it => it.type === "email")?.value)
            setAddress(item.find(it => it.type === "address")?.value)
            setPhone(item.find(it => it.type === "phone")?.value)
            setText(item.find(it => it.type === "footer_text")?.value)
            setWorkTime(item.find(it => it.type === "work_time")?.value)
            setWidgets(item.find(it => it.type === "widgets")?.value)
        }
    }, [item])


  return (
    <footer className="footer position-relative">
            <div className="footer-middle">
                <div className="container position-static">
                    <div className="footer-ribbon bg-primary text-white ls-0">
                        {localizationsItems?.contact_us}
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-4">
                            <div className="widget">
                                <h4 className="widget-title font-weight-bold">{localizationsItems?.contact_info}</h4>
                                <ul className="contact-info">
                                    {address &&
                                        <li>
                                            <span className="contact-info-label">{localizationsItems?.address}:</span>
                                            {address.map((ad, index) =>
                                                <p key={index}>{ad}</p>
                                            )}
                                        </li>
                                    }
                                    {phone &&
                                        <li>
                                            <span className="contact-info-label">{localizationsItems?.phone}:</span>
                                            {phone.map((ph, index) =>
                                                <a href={`tel:${ph}`} key={index}>{ph}</a>
                                            )}
                                        </li>
                                    }
                                    {email &&
                                        <li>
                                            <span className="contact-info-label">{localizationsItems?.email}:</span> 
                                            {email.map((em, index) =>
                                                <a href={`mailto:${em}`} key={index}>{em}</a>
                                            )}
                                        </li>
                                    }
                                    {workTime &&
                                        <li>
                                            <span className="contact-info-label">{localizationsItems?.work_time}:</span> 
                                            {workTime.map((wt, index) =>
                                                <p key={index}>{wt}</p>
                                            )}
                                        </li>
                                    }
                                </ul>
                                {socials &&
                                    <div className="social-icons">
                                        {socials.map((sc, index) =>
                                        sc.type === "tiktok" ?
                                            <a className='social-icon' key={index} href={sc.value} target='_blank'>
                                                <svg width="13" height="13" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className='footer-icon'>
                                                    <path d="M34.1451 0H26.0556V32.6956C26.0556 36.5913 22.9444 39.7913 19.0725 39.7913C15.2007 39.7913 12.0894 36.5913 12.0894 32.6956C12.0894 28.8696 15.1315 25.7391 18.8651 25.6V17.3913C10.6374 17.5304 4 24.2783 4 32.6956C4 41.1827 10.7757 48 19.1417 48C27.5075 48 34.2833 41.1131 34.2833 32.6956V15.9304C37.3255 18.1565 41.059 19.4783 45 19.5479V11.3391C38.9157 11.1304 34.1451 6.12173 34.1451 0Z" fill="white"/>
                                                </svg>
                                            </a>
                                            :
                                            <a href={sc.value} key={index} className={
                                                sc.type === "facebook" ? "social-icon social-facebook icon-facebook"
                                                :
                                                sc.type === "twitter" ? "social-icon social-twitter icon-twitter"
                                                :
                                                sc.type === "linkedin" ? "social-icon social-linkedin fab fa-linkedin-in"
                                                :
                                                sc.type === "instagram" ? "social-icon social-linkedin fab fa-instagram"
                                                :
                                                sc.type === "telegram" ? "social-icon social-linkedin fab fa-telegram"
                                                :
                                                sc.type === "youtube" ? "social-icon social-linkedin fab fa-youtube"
                                                :
                                                "social-icon social-facebook icon-facebook"
                                            } target="_blank" title="Facebook"></a>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8">
                            <div className="widget widget-newsletter">
                                <h4 className="widget-title">{localizationsItems?.subscribe_us}</h4>
                                <div className="row align-items-center">
                                    {text && <div className="col-lg-6 col-md-12 mb-1">
                                        {text.map((tx, index) =>
                                            <p className="mb-0" key={index}>{tx}</p>
                                        )}
                                    </div>}
                                </div>
                            </div>
                            <div className="row">
                                {items?.map((card, index) =>
                                    <div className="col-sm-5" key={index}>
                                        <div className="widget">
                                            <h4 className="widget-title mb-1">{card.title}</h4>
                                            {card.items &&
                                                <div className="links link-parts row">
                                                    <ul className="link-part col-lg-6 col-md-12 mb-0">
                                                        {card.items.map((el, index) =>
                                                            <li key={index}>
                                                                <NavLink to={el.value} target={el.type}>{el.title}</NavLink>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="footer-bottom d-sm-flex align-items-center">
                    <div className="footer-left">
                        <span className="footer-copyright">© Panama.kz</span>
                    </div>
                </div>
            </div>
        </footer>
  )
}

export default Footer