import React from 'react'
import { Modal } from 'react-bootstrap';
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import CatalogServices from '../../services/general/catalogServices';
import AddToCart from '../complex/AddToCart';
import { NavLink } from 'react-router-dom';

const ToCartModal = ({show, setShow, slug}) => {
    const handleClose = () => setShow(false);
    const {localizationsItems, lang} = useLangContext();

    const {info, loading} = useFetchInfo(CatalogServices.products.view, slug);

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {info &&
            <div>
                <div className='product-wrapper'>
                    <img src={'https://solution-0003.panama.kz' + info?.image} alt="" />
                    <NavLink to={`/${lang}/products/${slug}`}>
                        <h4>{info?.title}</h4>
                    </NavLink>
                </div>
                <AddToCart card={info}/>
            </div>}
        </Modal.Body>
    </Modal>
  )
}

export default ToCartModal