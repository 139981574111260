import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';

const LanguagesDropdown = () => {
    const {lang, changeLanguage, languages} = useLangContext();

  return (
    <div className="header-dropdown mr-auto mr-sm-3 mr-md-0">
        <span className='arrow'>{lang}</span>
        <div className="header-menu">
            <ul>
                {languages?.map((l, index) =>
                    <li key={index}>
                        <span className='lang' onClick={() => changeLanguage(lang.slug)}>{l.title}</span>
                    </li>
                )}
            </ul>
        </div>
    </div>
  )
}

export default LanguagesDropdown