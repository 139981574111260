import React from 'react'
import Pagination from '../simple/Pagination'
import useFetchItems from '../../hooks/useFetchItems'
import ServiceServices from '../../services/general/serviceServices'
import Servicecard from '../simple/Servicecard'

const ServicesContainer = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ServiceServices.index)

  return (
    items?.items &&
    <div className="container info-box-wrapper">
        <section className="img-section mb-2">
            <div className="row">
                {items.items.map((card, index) =>
                    <Servicecard card={card} key={index}/>
                )}
                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
            </div>
        </section>
    </div>
  )
}

export default ServicesContainer