import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import Utils from '../../services/utils';
import ShareBlock from '../simple/ShareBlock';

const EventDetailContainer = ({item}) => {
    const {lang} = useLangContext();
  return (
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <article className="post single">
                    <div className='mb-1'>
                        <img src={'https://solution-0003.panama.kz' + item.detail_image} alt="Post"/>
                    </div>
                    <div className="post-body mt-2">
                        <div className="post-date">
                            <span className="day">{Utils.generateDate2(item.date, lang).day}</span>
                            <span className="month">{Utils.generateDate2(item.date, lang).mon}</span>
                        </div>
                        <h2 className="post-title">{item.title}</h2>

                        <div className="post-content"
                            dangerouslySetInnerHTML={{__html: item.description}}>
                        </div>
                        <ShareBlock/>
                    </div>
                </article>
            </div>
        </div>
    </div>
  )
}

export default EventDetailContainer