import React, { useEffect, useState } from 'react'
import DropdownCartProduct from '../simple/DropdownCartProduct'
import { useLangContext } from '../../i18n/ProvideLang'
import useUid from '../../hooks/useUid.hook';
import { useBasketContext } from '../../hooks/ProvideBasket';
import CatalogServices from '../../services/general/catalogServices';
import Utils from '../../services/utils';
import { NavLink } from 'react-router-dom';

const SidebarCart = ({setOpenCart}) => {
    const {uid, setUid} = useUid();
    const [items, setItems] = useState();
    const {basketCount} = useBasketContext();

    const {localizationsItems, lang} = useLangContext();

    const [total, setTotal] = useState(0);

    const fetchItems = async () => {
        const res = await CatalogServices.cart.index(localStorage.getItem('uid'));
        if(res.statusCode === 200){
            setItems(res.content)
        }
    }

    useEffect(() => {
        if(items?.products){
            let count = items.products.reduce(
                (prev, item) => prev + (Number(item.price) * Number(item.count)), 0
            )
            setTotal(count)
        }else{
            setTotal(0)
        }
    }, [items])

    useEffect(() => {
        fetchItems() 
    }, [basketCount])

    const handleClose = () => {
        setOpenCart(false);
    }

  return (
    <div className="dropdown cart-dropdown show">
        <div onClick={(() => setOpenCart(true))} title="Cart" className="dropdown-toggle dropdown-arrow cart-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
            <i className="minicart-icon"></i>
            <span className="cart-count badge-circle">{items?.products?.length ? items?.products?.length : 0}</span>
        </div>

        <div className="cart-overlay" onClick={handleClose}></div>

        <div className="dropdown-menu mobile-cart">
            <div title="Close (Esc)" onClick={handleClose} className="btn-close1">×</div>

            <div className="dropdownmenu-wrapper custom-scrollbar">
                <div className="dropdown-cart-header">{localizationsItems?.cart_dropdown}</div>
                <div className="dropdown-cart-products">
                    {items?.products?.map((product, index) =>
                        <DropdownCartProduct key={index} product={product} uid={uid} fetchItems={fetchItems}/>
                    )}
                </div>
                <div className="dropdown-cart-total">
                    <span>{localizationsItems?.subtotal}</span>

                    <span className="cart-total-price float-right">{Utils.numberWithSpaces(total)} {localizationsItems?.currency}</span>
                </div>
                <div className="dropdown-cart-action">
                    <NavLink to={`/${lang}/cart`} onClick={handleClose} className="btn btn-gray btn-block view-cart">{localizationsItems?.view_cart}</NavLink>
                    <NavLink to={`/${lang}/checkout`} onClick={handleClose} className="btn btn-dark btn-block">{localizationsItems?.checkout}</NavLink>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SidebarCart