import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import CatalogServices from '../../services/general/catalogServices'
import { useLangContext } from '../../i18n/ProvideLang'
import { Collapse } from 'react-bootstrap'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ProductsCategory = ({setFilterData}) => {
    const [open, setOpen] = useState(false);
    const [filterItems, setFilterItems] = useState([]);
    const navigate = useNavigate();

    const {localizationsItems} = useLangContext();

    const {
        items,
    } = useFetchItems(CatalogServices.categories.index)

    const handleFilter = () => {
        let str = '';
        if(filterItems){
            for(let i = 0; i<filterItems.length; i++){
                if(i === filterItems.length-1){
                    str+=`categories[]=${filterItems[i]}`
                }else{
                    str+=`categories[]=${filterItems[i]}&`
                }
            }
        }
        setFilterData(str)
        navigate({
            search: str
        });
    }

    const handleReset = () => {
        setFilterData('')
        navigate({
            search: ''
        });
    }

    const setFilter = (value) => {
        if(filterItems.find(v => v === value)){
            let arr = filterItems.filter(it => it !== value)
            setFilterItems(arr)
        }else{
            setFilterItems([...filterItems, value])
        }
    }

  return (
    items &&
    <div>
        <div className="widget">
            <h3 className="widget-title">
                {localizationsItems?.categories}
            </h3>
            <div>
                <div className="widget-body" id="widget-body-2">
                    <ul className="cat-list">
                        {items.map((card, index) =>
                            <li key={index} onClick={() =>setFilter(card.slug)} className={filterItems.find(el => el === card.slug) ? 'cat-list-active' : ''}>
                                {card.title}<span className="products-count">({card.count})</span>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
        <div className="category-btn">
            <button className='btn btn-sm btn-quickview' onClick={handleReset}>{localizationsItems?.cancel}</button>
            <button className='btn btn-sm btn-primary' onClick={handleFilter}>{localizationsItems?.apply}</button>
        </div>
    </div>
  )
}

export default ProductsCategory