import React, { useEffect, useState } from 'react'
import LanguagesDropdown from '../simple/LanguagesDropdown'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang';

const HeaderTop = ({item, items}) => {
    const [socials, setSocials] = useState();

    const {localizationsItems} = useLangContext();

    useEffect(() =>{
        if(item){
            setSocials(item.find(it => it.type === "socials")?.value);
        }
    }, [item])

  return (
    <div className="container">
        <div className="header-top d-flex align-items-center w-100">
            <div className="header-left">
                <p className="top-message ls-0 text-uppercase d-none d-sm-block">{localizationsItems?.header_text}</p>
            </div>
            <div className="header-right header-dropdowns w-sm-100">
                {items && 
                    <div className="header-dropdown dropdown-expanded d-none d-lg-block mr-2">
                        <div className="header-menu">
                            <ul>
                                {items.map((card, index) =>
                                    <li key={index}>
                                        <NavLink to={card.value} target={card.type}>{card.title}</NavLink>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                }
                <span className="separator"></span>
                <LanguagesDropdown/>
                {socials && 
                    <>
                        <span className="separator"></span>
                        <div className="social-icons">
                            {socials.map((card, index) =>
                            card.value &&
                            card.type === "tiktok" ?
                                <a className='social-icon' key={index} href={card.value} target='_blank'><svg width="13" height="13" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M34.1451 0H26.0556V32.6956C26.0556 36.5913 22.9444 39.7913 19.0725 39.7913C15.2007 39.7913 12.0894 36.5913 12.0894 32.6956C12.0894 28.8696 15.1315 25.7391 18.8651 25.6V17.3913C10.6374 17.5304 4 24.2783 4 32.6956C4 41.1827 10.7757 48 19.1417 48C27.5075 48 34.2833 41.1131 34.2833 32.6956V15.9304C37.3255 18.1565 41.059 19.4783 45 19.5479V11.3391C38.9157 11.1304 34.1451 6.12173 34.1451 0Z" fill="white"/>
                                </svg></a>
                                :
                                <NavLink target="_blank" key={index} to={card.value} className={
                                    card.type === "facebook" ? "social-icon social-facebook icon-facebook" 
                                    :
                                    card.type === "twitter" ? "social-icon social-twitter icon-twitter" 
                                    :
                                    card.type === "instagram" ? "social-icon social-instagram icon-instagram" 
                                    :
                                    card.type === "youtube" ? "social-icon fab fa-youtube" 
                                    :
                                    card.type === "telegram" ? "social-icon fab fa-telegram" 
                                    :
                                    "social-icon social-facebook icon-facebook"
                                }></NavLink>
                            )}
                        </div>
                    </>
                }
            </div>
        </div>
    </div>
  )
}

export default HeaderTop