import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import imgPhone from "../../images/phone-white.png"
import HeaderSearch from '../simple/HeaderSearch';
import SidebarCart from './SidebarCart';
import MenuMobile from './MenuMobile';
import { useAuth } from '../../pages/user/ProvideAuth';

const HeaderMiddle = ({item, items, topItems, logo}) => {
    const {lang, localizationsItems} = useLangContext();
    const [phone, setPhone] = useState();
    const [openCart, setOpenCart] = useState();
    const [menuOpen, setMenuOpen] = useState();
    const {token} = useAuth();

    useEffect(() =>{
        if(item){
            setPhone(item.find(it => it.type === "phone")?.value);
        }
    }, [item])
    
  return (
    <div className={menuOpen ? 'mmenu-active' : ''}>
        <div className={openCart ? "header-middle sticky-header cart-opened" : "header-middle sticky-header"}>
            <div className="container">
                <div className="header-left col-lg-2 w-auto pl-0">
                    <button className="mobile-menu-toggler mr-2" type="button" onClick={() => setMenuOpen(true)}>
                        <i className="fas fa-bars"></i>
                    </button>
                    <NavLink to={`/${lang}`} className="logo">
                        <img src={'https://solution-0003.panama.kz' + logo} alt="Porto Logo"/>
                    </NavLink>
                </div>
                <div className="header-right w-lg-max ml-0">
                    <HeaderSearch/>
                    {phone && <div className="header-contact d-none d-lg-flex pl-4 ml-3 mr-xl-5">
                        <img alt="phone" src={imgPhone} width="30" height="30" className="pb-1"/>
                        <h6>{localizationsItems?.call_us}
                            {phone.map((ph, index) =>
                                <a href={`tel:${ph}`} className="font1" key={index}>{ph}</a>
                            )}
                        </h6>
                    </div>}
                    {token ?
                        <NavLink to={`/${lang}/dashboard`} className="header-icon d-inline-block" title="Login"><i className="icon-user-2"></i></NavLink>
                        :
                        <NavLink to={`/${lang}/login`} className="header-icon d-inline-block" title="Login"><i className="icon-user-2"></i></NavLink>
                    }
                    <NavLink to={`/${lang}/wishlist`} className="header-icon d-inline-block" title="Wishlist"><i className="icon-wishlist-2"></i></NavLink>
                    <SidebarCart setOpenCart={setOpenCart}/>
                </div>
            </div>
        </div>
        <MenuMobile setMenuOpen={setMenuOpen} items={items} topItems={topItems} item={item}/>
    </div>
  )
}

export default HeaderMiddle