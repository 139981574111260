import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

const MobMenuItem = ({it}) => {
    const [open, setOpen] = useState();

  return (
    it.items ?
        <li className={open ? 'open' : ''}>
            <div className='mobMenu-a' onClick={() => setOpen(!open)}>{it.title}
                <span className="mmenu-btn"></span>
            </div>
            {open && <ul>
                {it.items.map((el, index) =>
                    <li key={index}>
                        <NavLink to={el.value} target={el.type}>{el.title}</NavLink>
                    </li>
                )}
            </ul>}
        </li>
        :
        <li>
            <NavLink to={it.value} target={it.type}>{it.title}</NavLink>
        </li>
  )
}

export default MobMenuItem