import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchItems from '../../hooks/useFetchItems';
import ArticleServices from '../../services/general/articleServices';
import { NavLink, useNavigate } from 'react-router-dom';

const ArticleCategories = ({setFilterData}) => {
    const navigate = useNavigate();

    const {lang, localizationsItems} = useLangContext();

    const {
        items
    } = useFetchItems(ArticleServices.categories)

    const handleReset = () => {
        setFilterData('')
        navigate({
            search: ''
        });
    }

  return (
    items?.length >= 1 &&
    <div className="widget widget-categories">
        <h4 className="widget-title">{localizationsItems?.category_blog}</h4>

        <ul className="list">
            {items.map((card, index) =>
                <li key={index}>
                    <NavLink to={`/${lang}/articles?categories[]=${card.slug}`}>{card.name}</NavLink>
          
                    {card.items && 
                        <ul className="list">
                            <li><a href="#">Платья</a></li>
                        </ul>
                    }
                </li>  
            )}
            <li onClick={handleReset} className='cursor'>
                {localizationsItems?.all}
            </li>
        </ul>
    </div>
  )
}

export default ArticleCategories