import React, { useEffect, useState } from 'react'
import ProductSlider from '../simple/ProductSlider'
import Utils from '../../services/utils'
import { useLangContext } from '../../i18n/ProvideLang'
import AddToCart from './AddToCart'
import TabProduct from '../simple/TabProduct'
import SimilarProducts from '../simple/SimilarProducts'
import ProductsMix from './ProductsMix'
import ShareBlock from '../simple/ShareBlock'
import ProductDescription from '../simple/ProductDescription'

const ProductDetailContainer = ({item}) => {
    const [tabs, settabs] = useState();
    const [ids, setIds] = useState();

    const {localizationsItems} = useLangContext();

    useEffect(() => {
        if(item){
            settabs(item.elements?.find(it => it.type === "tab-component")?.elements);
            if(item.related_products){
                let str = ''
                item.related_products.forEach(element => {
                    str += `ids[]=${element}&` 
                });
                setIds(str);
            }
        }
    }, [item])

  return (
    <div className="container">
        <div className="product-single-container product-single-default">
            <div className="cart-message d-none">
                <strong className="single-cart-notice">{item.title}</strong>
                <span>{localizationsItems?.product_add_cart}</span>
            </div>

            <div className="row">
                <div className="col-lg-5 col-md-6 product-single-gallery">
                    {item.gallery && <ProductSlider slider={item.gallery}/>}
                </div>
                <div className="col-lg-7 col-md-6 product-single-details">
                    <h1 className="product-title">{item.title}</h1>

                    <hr className="short-divider"/>

                    <div className="price-box">
                        {item.old_price && <span className="old-price">{Utils.numberWithSpaces(item.old_price)} {localizationsItems?.currency}</span>}
                        <span className="new-price">{Utils.numberWithSpaces(item.price)} {localizationsItems?.currency}</span>
                    </div>
                    <div className="product-desc">
                        <p>
                            {item.subtitle}
                        </p>
                    </div>
                    {item.features &&
                    <ul className="single-info-list">
                        {item.features.map((it, index) =>
                            <li key={index}>
                                {it.title}:
                                <strong>{it.value}</strong>
                            </li>
                        )}
                    </ul>}
                    <AddToCart card={item}/>

                    <div className="product-single-share mb-2">
                        <ShareBlock/>
                        <a href="wishlist.html" className="btn-icon-wish add-wishlist" title="Add to Wishlist">
                            <i className="icon-wishlist-2"></i><span>{localizationsItems?.add_to_wishlist}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        {tabs ?
            <TabProduct item={tabs}/>
            :
            <ProductDescription desc={item.description}/>
        }
        {ids && <SimilarProducts/>}
        <ProductsMix/>
    </div>
  )
}

export default ProductDetailContainer