import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'
import CartTable from '../../components/complex/CartTable';
import useUid from '../../hooks/useUid.hook';
import useFetchItems from '../../hooks/useFetchItems';
import CatalogServices from '../../services/general/catalogServices';
import Utils from '../../services/utils';

const Cart = () => {
    const {uid, setUid} = useUid();

    const {localizationsItems, lang} = useLangContext();

    const [total, setTotal] = useState();

    const {
        items,
        fetchItems
    } = useFetchItems(CatalogServices.cart.index, uid)

    useEffect(() => {
        if(items?.products){
            let count = items.products.reduce(
                (prev, item) => prev + (Number(item.price) * Number(item.count)), 0
            )
            setTotal(count)
        }
    }, [items])

  return (
    <div className="container">
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <NavLink to={`/${lang}`}><i className="icon-home"></i></NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{localizationsItems?.cart}</li>
                </ol>
            </div>
        </nav>
        <div className="row justify-content-end mb-3">
            <div className="col-lg-12 mb-3">
                <CartTable items={items} uid={uid} fetchItems={fetchItems}/>
            </div>
            <div className="col-lg-4">
                <div className="cart-summary">
                    <p className='cart-total'>{localizationsItems?.cart_total}: <span>{Utils.numberWithSpaces(total)} {localizationsItems?.currency}</span></p>

                    <div className="checkout-methods">
                        <NavLink to={`/${lang}/checkout`} className="btn btn-block btn-dark">{localizationsItems?.to_checkout}
                            <i className="fa fa-arrow-right"></i></NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Cart