import React from 'react'
import HeaderTop from './HeaderTop'
import HeaderMiddle from './HeaderMiddle'
import Menu from './Menu'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import { useEffect } from 'react'
import { useState } from 'react'

const Header = ({item}) => {
    const [logo, setLogo] = useState();

    const {
        items,
    } = useFetchItems(AppServices.menu, 'main-menu')

    const {
        items:topItems,
    } = useFetchItems(AppServices.menu, 'top-menu')

    useEffect(() =>{
        if(item){
            setLogo(item.find(it => it.type === "logo")?.value);
        }
    }, [item])

  return (
    <header className="header">
        <HeaderTop item={item} items={topItems}/>
        <HeaderMiddle item={item} items={items} topItems={topItems} logo={logo}/>
        <Menu items={items} logo={logo}/>
    </header>
  )
}

export default Header