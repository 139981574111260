import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang'

const PageTopBanner = ({item}) => {
    const {localizationsItems} = useLangContext();

  return (
    item &&
    <div className="category-banner-container bg-gray">
        <div className="category-banner banner text-uppercase" style={{backgroundImage: `url(${'https://solution-0003.panama.kz' + item.detail_image})`}}>
            <div className="container position-relative">
                <div className="row">
                    <div className="pl-lg-5 pb-5 pb-md-0 col-sm-5 col-xl-4 col-lg-4 offset-1">
                        <h3 dangerouslySetInnerHTML={{__html: item.subtitle}}></h3>
                        {localizationsItems?.banner_button && <button className="btn btn-dark">{localizationsItems.banner_button}</button>}
                    </div>
                    <div className="pl-lg-3 col-sm-4 offset-sm-0 offset-1 pt-3">
                        <div className="coupon-sale-content" dangerouslySetInnerHTML={{__html: item.description}}></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PageTopBanner