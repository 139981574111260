import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import ArticleCard from '../simple/ArticleCard'
import ArticleCategories from '../simple/ArticleCategories'
import LatestPost from './LatestPost'
import Pagination from '../simple/Pagination'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ArticlesContainer = () => {
    let query = new URLSearchParams(useLocation().search);

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ArticleServices.index)

    useEffect(() => {
        if(query.get('categories[]')){
            setFilterData(query.toString())
        }
    }, [query.get('categories[]')])
    
  return (
    <div className="container">
        <div className="row">
            <div className="col-lg-9">
                <div className="blog-section row">
                    {items?.items?.map((card, index) =>
                        <ArticleCard card={card} key={index}/>
                    )}
                </div>
                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
            </div>
            <div className="sidebar-toggle custom-sidebar-toggle">
                <i className="fas fa-sliders-h"></i>
            </div>
            <div className="sidebar-overlay"></div>
            <aside className="sidebar mobile-sidebar col-lg-3">
                <div className="sidebar-wrapper" data-sticky-sidebar-options='{"offsetTop": 72}'>
                    <ArticleCategories setFilterData={setFilterData}/>
                    <LatestPost/>
                </div>
            </aside>
        </div>
    </div>
  )
}

export default ArticlesContainer