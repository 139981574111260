import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import Footer from '../components/complex/Footer';
import Header from '../components/complex/Header';
import AppServices from '../services/general/appServices';
import { ERROR_PAGE_ROUTE, MAIN_PAGE_ROUTE } from './Constants';
import { publicRoutes } from './Routes';

const AppRouter = () => {
    const [items, setItems] = useState();
    const [loading, setLoading] = useState(false);

    const fetchdata = async () => {
        setLoading(true)
        const res = await AppServices.settings();
    
        if(res.statusCode === 200){
            setItems(res.content)
        }
        setLoading(false)
    }
    useEffect(() => {
      fetchdata();
    }, [])

  useEffect(() => {
    if(items){
      let color = items.find(it => it.type === "color-primary")?.value;
      let footerColor = items.find(it => it.type === "footer-color")?.value;
      let bodyColor = items.find(it => it.type === "body-color")?.value;
      let headerColor = items.find(it => it.type === "header-color")?.value;
      let headerFontColor = items.find(it => it.type === "header-font-color")?.value;
      let footerFontColor = items.find(it => it.type === "footer-font-color")?.value;
      let footerTitleColor = items.find(it => it.type === "footer-title-color")?.value;
      let footerIconColor = items.find(it => it.type === "footer-icon-color")?.value;

      let head = items.find(it => it.type === "head")?.value;
      let bottom = items.find(it => it.type === "bottom")?.value;
      let header = items.find(it => it.type === "header")?.value;

      if(head){
        head.forEach(element => {
            const divFragment =  document.createRange().createContextualFragment(element)
            document.head.append(divFragment);
        });
      }

      if(header){
        header.forEach(element => {
          const divFragment =  document.createRange().createContextualFragment(element)
          document.body.append(divFragment);
        });
      }

      if(bottom){
        bottom.forEach(element => {
          const divFragment =  document.createRange().createContextualFragment(element)
          document.body.prepend(divFragment);
        });
      }

      color && document.documentElement.style.setProperty('--mainColor', color);
      footerColor && document.documentElement.style.setProperty('--footerColor', footerColor);
      bodyColor && document.documentElement.style.setProperty('--bodyColor', bodyColor);
      headerColor && document.documentElement.style.setProperty('--headerColor', headerColor);
      headerFontColor && document.documentElement.style.setProperty('--headerFontColor', headerFontColor);
      footerFontColor && document.documentElement.style.setProperty('--footerFontColor', footerFontColor);
      footerTitleColor && document.documentElement.style.setProperty('--footerTitleColor', footerTitleColor);
      footerIconColor && document.documentElement.style.setProperty('--footerIconColor', footerIconColor);
    }
  }, [items])

  return (
        <Routes>
            {publicRoutes.map(({path, Component}) => 
                <Route key={path} path={path} element={
                    <>
                        <Header item={items}/>
                        <Component></Component>
                        <Footer item={items}/>
                    </>
                } exact/>
            )}
            <Route
                path="*"
                exact
                element={<Navigate to={ERROR_PAGE_ROUTE}/>}
            />

        </Routes>
  )
}

export default AppRouter