import React from 'react'
import ProjectServices from '../../services/general/projectServices'
import useFetchItems from '../../hooks/useFetchItems'
import ProjectCard from '../simple/ProjectCard'
import Pagination from '../simple/Pagination'

const ProjectsContainer = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ProjectServices.index)

  return (
    items?.items &&
    <div className="container info-box-wrapper">
        <section className="img-section mb-2">
            <div className="row">
                {items.items.map((card, index) =>
                    <ProjectCard card={card} key={index}/>
                )}
                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
            </div>
        </section>
    </div>
  )
}

export default ProjectsContainer