import React from 'react'
import ProductsContainer from '../../components/complex/ProductsContainer'
import AppServices from '../../services/general/appServices'
import useFetchItems from '../../hooks/useFetchItems'
import { Helmet } from 'react-helmet'
import Advantages from '../../components/complex/Advantages'
import AdvertisingBanners from '../../components/complex/AdvertisingBanners'
import AdvertisingBannerAnimation from '../../components/complex/AdvertisingBannerAnimation'
import ProductsSlider from '../../components/complex/ProductsSlider'
import Partners from '../../components/complex/Partners'
import Features from '../../components/complex/Features'
import Reviews from '../../components/complex/Reviews'
import Gallery from '../../components/complex/Gallery'

const ProductsPage = () => {
    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'catalog')

  return (
    <>
        {loading ?
            <div className="preloader">
                <div className="isLoading"></div>
            </div>
            :
            items && <div>
                <Helmet>
                    <title>{items.seo_title ? items.seo_title : items.title}</title>
                    {items.seo_description && <meta name="description" content={items.seo_description} />}
                    {items.seo_keywords && <meta name="Keywords" content={items.seo_keywords}/>}
                </Helmet>
                <ProductsContainer item={items}/>
                {
                    items.elements?.map((elem, index) => 
                        <div key={index}>
                            {
                            elem.type === "steps" ?
                                <Advantages elem={elem}/>
                            :
                            elem.type === "advertising-banner-2" ?
                                Number(elem.banner_type) === 2 ?
                                    <AdvertisingBanners elem={elem}/>
                                    :
                                Number(elem.banner_type) === 3 ?
                                    <AdvertisingBannerAnimation elem={elem}/>
                                    :
                                    <></>
                            :
                            elem.type === "products" ?
                                <ProductsSlider elem={elem}/>
                            :
                            elem.type === "partners" ?
                                <Partners elem={elem}/>
                            :
                            elem.type === "advantages" ?
                                <Features elem={elem}/>
                            :
                            elem.type === "reviews" ?
                                <Reviews elem={elem}/>
                            :
                            elem.type === "gallery" ?
                                <Gallery elem={elem}/>
                            :
                                <></>
                            }
                        </div>
                )}
            </div>
        }
    </>
  )
}

export default ProductsPage