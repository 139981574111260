import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'
import CheckoutContainer from '../../components/complex/CheckoutContainer';

const Checkout = () => {
    const {lang, localizationsItems} = useLangContext();

  return (
    <div className="container checkout-container">
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <NavLink to={`/${lang}`}><i className="icon-home"></i></NavLink>
                    </li>
                    <li className="breadcrumb-item">
                        <NavLink to={`/${lang}`}>{localizationsItems?.cart}</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{localizationsItems?.checkout}</li>
                </ol>
            </div>
        </nav>
        <CheckoutContainer/>
    </div>
  )
}

export default Checkout