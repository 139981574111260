import React, {createContext, useContext} from 'react';
import useBasket from './useBasket.hook';

const basketContext = createContext(null);

const ProvideBasket = ({children}) => {
    const data = useBasket()

    return (
        <basketContext.Provider value={data}>
            {children}
        </basketContext.Provider>
    );
};

export default ProvideBasket;

export function useBasketContext() {
    return useContext(basketContext);
}