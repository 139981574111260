import React from 'react';
import useFetchItems from '../../hooks/useFetchItems';
import ArticleServices from '../../services/general/articleServices';
import { useLangContext } from '../../i18n/ProvideLang';
import { NavLink } from 'react-router-dom';
import img from "../../images/placeholder.png";

const LatestPost = () => {
    const {lang, localizationsItems} = useLangContext();

    const {
        items,
    } = useFetchItems(ArticleServices.index)

  return (
    items?.items &&
    <div className="widget widget-post">
        <h4 className="widget-title">{localizationsItems?.latest_posts}</h4>

        <ul className="simple-post-list">
            {items.items.map((card, index) =>
                <li key={index}>
                    <div className="post-media">
                        <NavLink to={`/${lang}/articles/${card.slug}`}>
                            <img src={card.image ? 'https://solution-0003.panama.kz' + card.image : img} alt="Post"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img;
                                }}
                            />
                        </NavLink>
                    </div>
                    <div className="post-info">
                        <NavLink to={`/${lang}/articles/${card.slug}`}>{card.title}</NavLink>
                        <div className="post-meta">{card.date}</div>
                    </div>
                </li>
            )}
        </ul>
    </div>
  )
}

export default LatestPost