import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';

const MobSearch = () => {
    const [term, setTerm] = useState('');

    const navigate = useNavigate();

    const {localizationsItems} = useLangContext();
    
    const searchOn = () => {
        navigate(`/ru/catalog?term=${term}`);
    }

    const dynamicSearch = (e) => {
        setTerm(e.target.value);
    }

  return (
    <form className="search-wrapper mb-2" onSubmit={searchOn}>
        <input type="text" className="form-control mb-0 control-white" value={term} onChange={(e) => dynamicSearch(e)} placeholder={localizationsItems?.search} required />
        <button className="btn icon-search text-white bg-transparent p-0" type="submit"></button>
    </form>
  )
}

export default MobSearch