import React from 'react'

const TabOrders = () => {
  return (
    <div className="order-content">
        <h3 className="account-sub-title d-none d-md-block"><i
                className="sicon-social-dropbox align-middle mr-3"></i>Заказы</h3>
        <div className="order-table-container text-center">
            <table className="table table-order text-left">
                <thead>
                    <tr>
                        <th className="order-id">ЗАКАЗ</th>
                        <th className="order-date">ДАТА</th>
                        <th className="order-status">СТАТУС</th>
                        <th className="order-price">ИТОГО</th>
                        <th className="order-action">ДЕЙСТВИЯ</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-center p-0" colspan="5">
                            <p className="mb-5 mt-5">
                                Заказ еще не сделан.
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr className="mt-0 mb-3 pb-2" />

            <a href="category.html" className="btn btn-dark">Перейти в магазин</a>
        </div>
    </div>
  )
}

export default TabOrders