import React from 'react'

const TabEdit = () => {
  return (
    <>
        <h3 className="account-sub-title d-none d-md-block mt-0 pt-1 ml-1"><i className="icon-user-2 align-middle mr-3 pr-1"></i>Подробности аккаунта</h3>
        <div className="account-content">
            <form action="#">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="acc-name">Имя <span className="required">*</span></label>
                            <input type="text" className="form-control" placeholder="Редактор"
                                id="acc-name" name="acc-name" required />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="acc-lastname">Фамилия <span
                                    className="required">*</span></label>
                            <input type="text" className="form-control" id="acc-lastname"
                                name="acc-lastname" required />
                        </div>
                    </div>
                </div>

                <div className="form-group mb-2">
                    <label for="acc-text">Отображаемое имя <span className="required">*</span></label>
                    <input type="text" className="form-control" id="acc-text" name="acc-text"
                        placeholder="Редактор" required />
                    <p>Именно так ваше имя будет отображаться в личном кабинете
                        и
                        в отзывах</p>
                </div>


                <div className="form-group mb-4">
                    <label for="acc-email">адрес электронной почты <span className="required">*</span></label>
                    <input type="email" className="form-control" id="acc-email" name="acc-email"
                        placeholder="editor@gmail.com" required />
                </div>

                <div className="change-password">
                    <h3 className="text-uppercase mb-2">Изменить пароль</h3>

                    <div className="form-group">
                        <label for="acc-password">Текущий пароль (оставьте пустым, чтобы оставить
                            без изменений)</label>
                        <input type="password" className="form-control" id="acc-password"
                            name="acc-password" />
                    </div>

                    <div className="form-group">
                        <label for="acc-password">Новый пароль (оставьте пустым, чтобы оставить
                            без изменений)</label>
                        <input type="password" className="form-control" id="acc-new-password"
                            name="acc-new-password" />
                    </div>

                    <div className="form-group">
                        <label for="acc-password">Подтвердите новый пароль</label>
                        <input type="password" className="form-control" id="acc-confirm-password"
                            name="acc-confirm-password" />
                    </div>
                </div>

                <div className="form-footer mt-3 mb-0">
                    <button type="submit" className="btn btn-dark mr-0">
                        Сохранить изменения
                    </button>
                </div>
            </form>
        </div>
    </>
  )
}

export default TabEdit