import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import { NavLink } from 'react-router-dom';

const OrderSuccess = () => {
    const {lang, localizationsItems} = useLangContext();

  return (
    <div className='container order-success'>
        <div className="row justify-content-center mt-2 mb-2">
            <div className="col-auto text-center">
                <i className='fa fa-check-circle'></i>
                <h3>{localizationsItems?.order_thanks}</h3>
                <p>{localizationsItems?.order_success_content}</p>
                <NavLink to={`/${lang}`} className="btn btn-dark btn-place-order">{localizationsItems?.to_main}</NavLink>
            </div>
        </div>
    </div>
  )
}

export default OrderSuccess