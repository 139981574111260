import React, { useEffect, useState } from 'react'
import PageTop from '../../components/simple/PageTop'
import ProductDetailContainer from '../../components/complex/ProductDetailContainer'
import { useLangContext } from '../../i18n/ProvideLang'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import useFetchInfo from '../../hooks/useFetchInfo.hook'
import CatalogServices from '../../services/general/catalogServices'
import { Helmet } from 'react-helmet'
import Advantages from '../../components/complex/Advantages'
import AdvertisingBanners from '../../components/complex/AdvertisingBanners'
import AdvertisingBannerAnimation from '../../components/complex/AdvertisingBannerAnimation'
import ProductsSlider from '../../components/complex/ProductsSlider'
import Partners from '../../components/complex/Partners'
import Features from '../../components/complex/Features'
import Reviews from '../../components/complex/Reviews'
import Gallery from '../../components/complex/Gallery'

const ProductDetail = () => {
    const {lang, localizationsItems} = useLangContext();
    const {slug} = useParams();
    const navigate = useNavigate();
    const [ids, setIds] = useState();
    const {lang: lang2} = useParams();

    const {info, loading} = useFetchInfo(CatalogServices.products.view, slug, null, lang);

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/products/${slug}`)
            }
        }
    }, [lang])

  return (
    <>
        {loading ?
            <div className="preloader">
                <div className="isLoading"></div>
            </div>
            :
            info && <div>
                <Helmet>
                    <title>{info.seo_title ? info.seo_title : info.title}</title>
                    {info.seo_description && <meta name="description" content={info.seo_description} />}
                    {info.seo_keywords && <meta name="Keywords" content={info.seo_keywords}/>}
                </Helmet>
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div className="container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <NavLink to={`/${lang}`}><i className="icon-home"></i></NavLink>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                <NavLink to={`/${lang}/catalog`}>{localizationsItems?.catalog}</NavLink>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{info.title}</li>
                        </ol>
                    </div>
                </nav>
                <ProductDetailContainer item={info}/>
                {
                    info.elements?.map((elem, index) => 
                        <div key={index}>
                            {
                            elem.type === "steps" ?
                                <Advantages elem={elem}/>
                            :
                            elem.type === "advertising-banner-2" ?
                                Number(elem.banner_type) === 2 ?
                                    <AdvertisingBanners elem={elem}/>
                                    :
                                Number(elem.banner_type) === 3 ?
                                    <AdvertisingBannerAnimation elem={elem}/>
                                    :
                                    <></>
                            :
                            elem.type === "products" ?
                                <ProductsSlider elem={elem}/>
                            :
                            elem.type === "partners" ?
                                <Partners elem={elem}/>
                            :
                            elem.type === "advantages" ?
                                <Features elem={elem}/>
                            :
                            elem.type === "reviews" ?
                                <Reviews elem={elem}/>
                            :
                            elem.type === "gallery" ?
                                <Gallery elem={elem}/>
                            :
                                <></>
                            }
                        </div>
                )}
            </div>
        }
    </>
  )
}

export default ProductDetail