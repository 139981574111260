import React from 'react'
import { useForm } from 'react-hook-form';
import { useLangContext } from '../../i18n/ProvideLang';
import AuthServices from '../../services/general/authServices';
import { useNavigate } from 'react-router-dom';

const Registration = () => {
    const navigate = useNavigate();

    const {lang, localizationsItems} = useLangContext();

	const {handleSubmit, register, getValues, setValue, control, formState: {errors}} = useForm();

	const CustomSubmit = async (data) => {
        const res = await AuthServices.register(data);

        if (res.statusCode === 200) {
			navigate(`/${lang}`);
        }else{
			console.log(res)
		}
    }

  return (
    <div className="container login-container">
        <div className="row">
            <div className="col-lg-12 mt-5 mb-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="heading mb-1">
                            <h2 className="title">{localizationsItems?.register}</h2>
                        </div>
                        <form onSubmit={handleSubmit(CustomSubmit)}>
                            <label>{localizationsItems?.name}
                                <span className="required">*</span>
                            </label>
                            <input type="text" {...register('name')} className="form-input form-wide" required />

                            <label>{localizationsItems?.email}
                                <span className="required">*</span>
                            </label>
                            <input type="email" {...register('email')} className="form-input form-wide" required />

                            <label>{localizationsItems?.login_password}
                                <span className="required">*</span>
                            </label>
                            <input type="password" {...register('password')} className="form-input form-wide" required />

                            <label>{localizationsItems?.password_confirmation}
                                <span className="required">*</span>
                            </label>
                            <input type="password" {...register('password_confirmation')} className="form-input form-wide" required />

                            <button type="submit" className="btn btn-dark btn-md w-100">
                                {localizationsItems?.register}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Registration