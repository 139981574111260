import React from 'react'
import { NavLink } from 'react-router-dom'
import MobMenuItem from '../simple/MobMenuItem'
import { useState } from 'react';
import { useEffect } from 'react';
import MobSearch from '../simple/MobSearch';

const MenuMobile = ({setMenuOpen, items, topItems, item}) => {
    const [socials, setSocials] = useState();

    useEffect(() =>{
        if(item){
            setSocials(item.find(it => it.type === "socials")?.value);
        }
    }, [item])

  return (
    <>
        <div className="mobile-menu-overlay" onClick={() => setMenuOpen(false)}></div>
        <div className="mobile-menu-container">
            <div className="mobile-menu-wrapper">
                <span className="mobile-menu-close" onClick={() => setMenuOpen(false)}><i className="fa fa-times"></i></span>
                <nav className="mobile-nav">
                    <ul className="mobile-menu">
                        {items?.map((it, index) =>
                            <MobMenuItem key={index} it={it}/>
                        )}
                    </ul>
                    {topItems &&
                        <ul className="mobile-menu">
                            {topItems.map((el, index) =>
                                <li key={index}><NavLink to={el.value} target={el.type}>{el.title}</NavLink></li>
                            )}
                        </ul>
                    }
                </nav>
                <MobSearch/>
                {socials
                    &&
                        <div className="social-icons">
                            {socials.map((card, index) =>
                            card.value &&
                            card.type === "tiktok" ?
                                <a className='social-icon' key={index} href={card.value} target='_blank'><svg width="13" height="13" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M34.1451 0H26.0556V32.6956C26.0556 36.5913 22.9444 39.7913 19.0725 39.7913C15.2007 39.7913 12.0894 36.5913 12.0894 32.6956C12.0894 28.8696 15.1315 25.7391 18.8651 25.6V17.3913C10.6374 17.5304 4 24.2783 4 32.6956C4 41.1827 10.7757 48 19.1417 48C27.5075 48 34.2833 41.1131 34.2833 32.6956V15.9304C37.3255 18.1565 41.059 19.4783 45 19.5479V11.3391C38.9157 11.1304 34.1451 6.12173 34.1451 0Z" fill="white"/>
                                </svg></a>
                                :
                                <NavLink target="_blank" key={index} to={card.value} className={
                                    card.type === "facebook" ? "social-icon social-facebook icon-facebook" 
                                    :
                                    card.type === "twitter" ? "social-icon social-twitter icon-twitter" 
                                    :
                                    card.type === "instagram" ? "social-icon social-instagram icon-instagram" 
                                    :
                                    card.type === "youtube" ? "social-icon fab fa-youtube" 
                                    :
                                    card.type === "telegram" ? "social-icon fab fa-telegram" 
                                    :
                                    "social-icon social-facebook icon-facebook"
                                
                                }></NavLink>
                            )}
                        </div>
                }
            </div>
        </div>
    </>
  )
}

export default MenuMobile