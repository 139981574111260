import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReviewCard from '../simple/ReviewCard';

const Reviews = ({elem}) => {
    const settings = {
        margin: 20,
        lazyLoad: true,
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            992: {
                items: 2
            }
        }
    }


  return (
    <div className="testimonials-section">
        <div className="container">
            <h2 className="subtitle text-center">{elem.title}</h2>
            {elem.elements?.length >= 1 && <OwlCarousel {...settings} className="testimonials-carousel owl-carousel owl-theme images-left">
                {elem.elements.map((card, index) =>
                    <ReviewCard card={card} key={index}/>
                )}
            </OwlCarousel>}
        </div>
    </div>
  )
}

export default Reviews