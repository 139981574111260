import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang'

const ProductDescription = ({desc}) => {
    const {localizationsItems} = useLangContext();

  return (
    <div className="product-single-tabs">
        <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
                <a className="nav-link active" id="product-tab-desc" data-toggle="tab"
                    href="#product-desc-content" role="tab" aria-controls="product-desc-content"
                    aria-selected="true">{localizationsItems?.description}</a>
            </li>
        </ul>

        <div className="tab-content">
            <div className="tab-pane fade show active" id="product-desc-content" role="tabpanel"
                aria-labelledby="product-tab-desc">
                <div className="product-desc-content"
                    dangerouslySetInnerHTML={{__html: desc}}
                ></div>
            </div>
        </div>
    </div>
  )
}

export default ProductDescription