import React from 'react';
import img from "../../images/placeholder.png";

const ReviewCard = ({card}) => {
  return (
    <div className="testimonial">
        <div className="testimonial-owner">
            <figure>
                <img src={card.image ? 'https://solution-0003.panama.kz' + card.image : img} alt="client"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img;
                    }}
                />
            </figure>
            <div>
                <strong className="testimonial-title">{card.title}</strong>
                <span>{card.subtitle}</span>
            </div>
        </div>
        <blockquote>
            <p dangerouslySetInnerHTML={{__html: card.description}}></p>
        </blockquote>
    </div>
  )
}

export default ReviewCard