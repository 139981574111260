import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import AppServices from '../../services/general/appServices';
import { useLangContext } from '../../i18n/ProvideLang';
import Utils from '../../services/utils';

const CheckoutModal = ({show, setShow, productName}) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const {localizationsItems} = useLangContext();

    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const handleClose = () => setShow(false);

    const CustomSubmit = async (data) => {
        let query = new URLSearchParams(window.location.search);

        const {name, phone} = data;
        
        let newData = {
            fields: {
                Имя: name, 
                Номер: phone,
                Товар: productName
            }
        }

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            Utils.getCookie('utm_source') && newData.append('utm_source', Utils.getCookie('utm_source'));
            Utils.getCookie('utm_medium') && newData.append('utm_medium', Utils.getCookie('utm_medium'));
            Utils.getCookie('utm_campaign') && newData.append('utm_campaign', Utils.getCookie('utm_campaign'));
            Utils.getCookie('utm_content') && newData.append('utm_content', Utils.getCookie('utm_content'));
            Utils.getCookie('utm_term') && newData.append('utm_term', Utils.getCookie('utm_term'));
        }

        setLoading(true);

        const res = await AppServices.requestTemplate(newData);
    
        if (res.statusCode === 200) {
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                handleClose();
            }, 3000);
        }
        setLoading(false);
    };

  return (
    <Modal show={show} onHide={handleClose} centered size='lg' className='checkout-form'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(CustomSubmit)}>
                <div className="row justify-content-center">
                    <div className="text-center">
                        <h3>{localizationsItems?.checkout_order}</h3>
                        <p>{localizationsItems?.checkout_content}</p>
                    </div>
                    <div className="col-sm-12 col-md-10">
                        <label for="login-email">
                            {localizationsItems?.checkout_name}
                            <span class="required">*</span>
                        </label>
                        <input {...register('name')} type="name" class="form-input form-wide mb-2" id="login-email" required />
                    </div>
                    <div className="col-sm-12 col-md-10">
                        <label for="login-email">
                            {localizationsItems?.checkout_phone}
                            <span class="required">*</span>
                        </label>
                        <input {...register('email')} type="email" class="form-input form-wide mb-2" id="login-email" required />
                    </div>
                    <div className="col-sm-12 col-md-10">
                        <div className="text-right mt-2">
                            <button type="submit" className="btn btn-dark add-cart mr-2 added-to-cart">{localizationsItems?.checkout}</button>
                        </div>
                    </div>
                </div>
            </form>
            <div className={visible ? 'form-success form-visible' : 'form-success'}>
                <i className='fa fa-check-circle'></i>
                <h2>{localizationsItems?.form_success_title}</h2>
                <p>{localizationsItems?.form_success_text}</p>
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default CheckoutModal