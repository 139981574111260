import React from 'react'
import DashboardContainer from '../../components/complex/DashboardContainer'
import PageTop from '../../components/simple/PageTop'
import { useLangContext } from '../../i18n/ProvideLang'

const Dashboard = () => {
    const {localizationsItems} = useLangContext();

    const item ={
        title: localizationsItems?.dashboard
    }
  return (
    <div>
        <PageTop item={item}/>
        <DashboardContainer/>
    </div>
  )
}

export default Dashboard