import React from 'react'
import ArticleCategories from '../simple/ArticleCategories'
import LatestPost from './LatestPost'
import Utils from '../../services/utils'
import { useLangContext } from '../../i18n/ProvideLang'
import ShareBlock from '../simple/ShareBlock'

const ArticleDetailContainer = ({item}) => {
    const {lang} = useLangContext();

  return (
    <div className="container">
        <div className="row">
            <div className="col-lg-9">
                <article className="post single">
                    <div className='mb-1'>
                        <img src={'https://solution-0003.panama.kz' + item.detail_image} alt="Post"/>
                    </div>
                    <div className="post-body mt-2">
                        <div className="post-date">
                            <span className="day">{Utils.generateDate2(item.date, lang).day}</span>
                            <span className="month">{Utils.generateDate2(item.date, lang).mon}</span>
                        </div>
                        <h2 className="post-title">{item.title}</h2>

                        <div className="post-content"
                            dangerouslySetInnerHTML={{__html: item.description}}>
                        </div>
                        <ShareBlock/>
                    </div>
                </article>
            </div>
            <div className="sidebar-toggle custom-sidebar-toggle">
                <i className="fas fa-sliders-h"></i>
            </div>
            <div className="sidebar-overlay"></div>
            <aside className="sidebar mobile-sidebar col-lg-3">
                <div className="sidebar-wrapper" data-sticky-sidebar-options='{"offsetTop": 72}'>
                    <ArticleCategories/>
                    <LatestPost/>
                </div>
            </aside>
        </div>
    </div>
  )
}

export default ArticleDetailContainer