import React, { useEffect, useState } from 'react'
import MenuItem from '../simple/MenuItem'
import { NavLink } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';

const Menu = ({items, logo}) => {
    const [sticky, setStiky] = useState(false);

    const {lang} = useLangContext();

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 120){
            setStiky(true)
        }else{
            setStiky(false)
        }
    };
    
    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

  return (
    <div className={sticky ? "header-bottom sticky-header d-lg-block d-none fixed" : "header-bottom sticky-header d-lg-block d-none"}>
        <div className="container">
            <div className='header-left'>
                <NavLink to={`/${lang}`}>
                    <img src={'https://solution-0003.panama.kz' + logo} alt="logo" className='logo'/>
                </NavLink>
            </div>
            <div className="header-center">
                <nav className="main-nav w-100">
                    <ul className="menu">
                        {items?.map((card, index) =>
                            <MenuItem card={card} key={index}/>
                        )}
                    </ul>
                </nav>
            </div>
        </div>
    </div>
  )
}

export default Menu