import React, { useEffect, useState } from 'react'
import useUid from '../../hooks/useUid.hook';
import { useLangContext } from '../../i18n/ProvideLang';
import { useNavigate } from 'react-router-dom';
import { useBasketContext } from '../../hooks/ProvideBasket';
import { useForm } from 'react-hook-form';
import useFetchItems from '../../hooks/useFetchItems';
import CatalogServices from '../../services/general/catalogServices';
import DictionariesServices from '../../services/general/dictionariesServices copy';
import Utils from '../../services/utils';

const CheckoutContainer = () => {
    const {uid, removeUid} = useUid();
    const {localizationsItems, lang} = useLangContext();

    const navigate = useNavigate();
    const {changeCountBasket} = useBasketContext();

    const [total, setTotal] = useState();

    const [activePayment, setActivePayment] = useState();
    const [activeDelivery, setActiveDelivery] = useState();

    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const {
        items,
    } = useFetchItems(CatalogServices.cart.index, uid)
    
    const {
        items:payments,
    } = useFetchItems(DictionariesServices.orderPayments)

    const {
        items:deliveries,
    } = useFetchItems(DictionariesServices.orderDeliveries)

    useEffect(() => {
        if(items?.products){
            let count = items.products.reduce(
                (prev, item) => prev + (Number(item.price) * Number(item.count)), 0
            )
            setTotal(count)
        }
    }, [items])

    const CustomSubmit = async (data) => {
        const newData ={
            ...data,
            uid: uid
        }

        const res = await CatalogServices.cart.order(newData);
        
        if(res.statusCode === 200){
            removeUid()
            changeCountBasket(Math.random().toString(16))
            navigate(`/${lang}/order-success`)
        }
    }

  return (
    <form id="checkout-form" onSubmit={handleSubmit(CustomSubmit)} className="row mb-3">
        <div className="col-lg-7">
            <ul className="checkout-steps">
                <li>
                    <h3 className="step-title">{localizationsItems?.order_detail}</h3>
                    <div>
                        <div className="form-group">
                            <label>{localizationsItems?.checkout_name}
                                <abbr className="required" title="required">*</abbr></label>
                            <input type="text" {...register('name', {required: true})} className="form-control" required />
                        </div>

                        <div className="form-group">
                            <label>{localizationsItems?.checkout_phone} <abbr className="required" title="required">*</abbr></label>
                            <input type="tel" {...register('phone', {required: true})} className="form-control" required />
                        </div>

                        <div className="form-group">
                            <label>{localizationsItems?.checkout_email}
                                <abbr className="required" title="required">*</abbr></label>
                            <input type="email" {...register('email', {required: true})} className="form-control" required />
                        </div>

                        <div className="form-group">
                            <label className="order-comments">{localizationsItems?.checkout_comment}</label>
                            <textarea className="form-control" {...register('comment', {required: true})} placeholder="..." required></textarea>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div className="col-lg-5">
            <div className="order-summary">
                <h3>{localizationsItems?.your_order}</h3>

                <table className="table table-mini-cart">
                    <thead>
                        <tr>
                            <th colspan="2">{localizationsItems?.product}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {items?.products?.map((product, index) =>
                        <tr key={index}>
                            <td className="product-col">
                                <h3 className="product-title">
                                    {product.title} ×
                                    <span className="product-qty"> {product.count}</span>
                                </h3>
                            </td>
                            <td className="price-col">
                                <span>{Utils.numberWithSpaces(Number(product.price) * Number(product.count))} {localizationsItems?.currency}</span>
                            </td>
                        </tr>
                    )}  
                    </tbody>
                    <tfoot>
                        <tr className="cart-subtotal">
                            <td>
                                <h4>{localizationsItems?.subtotal}</h4>
                            </td>
                            <td className="price-col">
                                <span>{Utils.numberWithSpaces(total)} {localizationsItems?.currency}</span>
                            </td>
                        </tr>
                        {deliveries && <tr className="order-shipping">
                            <td className="text-left" colspan="2">
                                <h4 className="m-b-sm mt-1">{localizationsItems?.delivery_method} *</h4>
                                <ul className={errors.delivery_id ? "payment-methods__list error" : "payment-methods__list"}>
                                    {deliveries.map((delivery, index) =>
                                        <li className={activeDelivery === delivery ? "payment-methods__item payment-methods__item--active" : "payment-methods__item"} key={index} 
                                            onClick={() => setActiveDelivery(delivery)}
                                            >
                                            <label className="payment-methods__item-header">
                                                <span className="payment-methods__item-radio input-radio">
                                                    <span className="input-radio__body">
                                                        <input className="input-radio__input" name="checkout_payment_method" value={delivery.id} type="radio" {...register('payment_id', {required: true})}/>
                                                        <span className="input-radio__circle"></span>
                                                    </span>
                                                </span>
                                                <span className="payment-methods__item-title">{delivery.name}</span>
                                            </label>
                                            <div className="payment-methods__item-container">
                                                <div className="payment-methods__item-description text-muted">
                                                    {delivery.description}
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </td>
                        </tr>}
                        {payments && <tr className="order-shipping">
                            <td className="text-left" colspan="2">
                                <h4 className="m-b-sm mt-1">{localizationsItems?.payment_method} *</h4>
                                <ul className={errors.payment_id ? "payment-methods__list error" : "payment-methods__list"}>
                                    {payments.map((payment, index) =>
                                        <li className={activePayment === payment ? "payment-methods__item payment-methods__item--active" : "payment-methods__item"} key={index} 
                                            onClick={() => setActivePayment(payment)}
                                            >
                                            <label className="payment-methods__item-header">
                                                <span className="payment-methods__item-radio input-radio">
                                                    <span className="input-radio__body">
                                                        <input className="input-radio__input" name="checkout_payment_method" value={payment.id} type="radio" {...register('payment_id', {required: true})}/>
                                                        <span className="input-radio__circle"></span>
                                                    </span>
                                                </span>
                                                <span className="payment-methods__item-title">{payment.name}</span>
                                            </label>
                                            <div className="payment-methods__item-container">
                                                <div className="payment-methods__item-description text-muted">
                                                    {payment.description}
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </td>
                        </tr>}

                        <tr className="order-total">
                            <td>
                                <h4>{localizationsItems?.total}</h4>
                            </td>
                            <td>
                                <b className="total-price"><span>{Utils.numberWithSpaces(total)} {localizationsItems?.currency}</span></b>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                <button type="submit" className="btn btn-dark btn-place-order" form="checkout-form">
                    {localizationsItems?.checkout}
                </button>
            </div>
        </div>
    </form>
  )
}

export default CheckoutContainer