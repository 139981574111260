import React from 'react'
import { NavLink } from 'react-router-dom'

const MenuItem = ({card}) => {
  return (
    <li>
        <NavLink to={card.value} target={card.type}>{card.title}</NavLink>
        {card.items &&
            <ul className='sub-menu'>
                {card.items.map((it, index) =>
                    <li key={index}>
                        <NavLink to={it.value} target={it.type}>{it.title}</NavLink>
                        {it.items &&
                            <ul className='submenu'>
                                {it.items.map((el, index) =>
                                    <li key={index}>
                                        <NavLink to={el.value} target={el.type}>{el.title}</NavLink>
                                    </li>
                                )}
                            </ul>
                        }
                    </li>
                )}
            </ul>
        }
    </li>
  )
}

export default MenuItem