import React from 'react'
import Utils from '../../services/utils'
import { useLangContext } from '../../i18n/ProvideLang'
import { NavLink } from 'react-router-dom';
import img from "../../images/placeholder.png";

const ArticleCard = ({card}) => {
    const {lang} = useLangContext();
  return (
    <div className="col-md-6 col-lg-4">
        <article className="post">
            <div className="post-media article-post">
                <NavLink to={`/${lang}/articles/${card.slug}`}>
                    <img src={card.image ? 'https://solution-0003.panama.kz' + card.image : img} alt="Post" width="225" height="280"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img;
                        }}
                    />
                </NavLink>
                <div className="post-date">
                    <span className="day">{Utils.generateDate2(card.date, lang).day}</span>
                    <span className="month">{Utils.generateDate2(card.date, lang).mon}</span>
                </div>
            </div>
            <div className="post-body">
                <h2 className="post-title">
                    <NavLink to={`/${lang}/articles/${card.slug}`}>{card.title}</NavLink>
                </h2>
                <div className="post-content">
                    <p>{card.description}</p>
                </div>
            </div>
        </article>
    </div>
  )
}

export default ArticleCard