import React, { useEffect } from 'react'
import PageTop from '../../components/simple/PageTop'
import ArticleDetailContainer from '../../components/complex/ArticleDetailContainer'
import { useNavigate, useParams } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import ArticleServices from '../../services/general/articleServices';
import useFetchInfo from '../../hooks/useFetchInfo.hook'
import { Helmet } from 'react-helmet';
import Advantages from '../../components/complex/Advantages';
import AdvertisingBanners from '../../components/complex/AdvertisingBanners';
import Partners from '../../components/complex/Partners';
import ProductsSlider from '../../components/complex/ProductsSlider';
import AdvertisingBannerAnimation from '../../components/complex/AdvertisingBannerAnimation'
import Gallery from '../../components/complex/Gallery'
import Features from '../../components/complex/Features'
import Reviews from '../../components/complex/Reviews'

const ArticleDetail = () => {
    const {slug} = useParams();
    const {lang, localizationsItems} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/articles/${slug}`)
            }
        }
    }, [lang])

    const {info, loading} = useFetchInfo(ArticleServices.view, slug, null, lang);

  return (
    <>
        {loading ?
            <div className="preloader">
                <div className="isLoading"></div>
            </div>
            :
            <div>
                {info &&
                <Helmet>
                    <title>{info.seo_title ? info.seo_title : info.title}</title>
                    {info.seo_description && <meta name="description" content={info.seo_description} />}
                    {info.seo_keywords && <meta name="Keywords" content={info.seo_keywords}/>}
                </Helmet>}
                <PageTop item={info} subLink={`/${lang}/articles`} subTitle={localizationsItems?.articles}/>
                <ArticleDetailContainer item={info}/>
                {
                    info?.elements?.map((elem, index) => 
                        <div key={index}>
                            {
                            elem.type === "steps" ?
                                <Advantages elem={elem}/>
                            :
                            elem.type === "advertising-banner-2" ?
                                Number(elem.banner_type) === 2 ?
                                    <AdvertisingBanners elem={elem}/>
                                    :
                                Number(elem.banner_type) === 3 ?
                                    <AdvertisingBannerAnimation elem={elem}/>
                                    :
                                    <></>
                            :
                            elem.type === "products" ?
                                <ProductsSlider elem={elem}/>
                            :
                            elem.type === "partners" ?
                                <Partners elem={elem}/>
                            :
                            elem.type === "advantages" ?
                                <Features elem={elem}/>
                            :
                            elem.type === "reviews" ?
                                <Reviews elem={elem}/>
                            :
                            elem.type === "gallery" ?
                                <Gallery elem={elem}/>
                            :
                                <></>
                            }
                        </div>
                )}
            </div>
        }
    </>
  )
}

export default ArticleDetail