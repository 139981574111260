import React from 'react';
import { NavLink } from 'react-router-dom';
import img from "../../images/placeholder.png";

const AdvertisingBanners = ({elem}) => {
  return (
    <div className="container mt-2">
        <div className="banners-grid text-uppercase grid">
            {elem?.elements?.map((card, index) =>
                <div className="banner banner1 grid-item banner-md-vw w-md-100 text-transform-none w-xxs-100 appear-animate" key={index} data-animation-name="fadeInUpShorter" data-animation-delay="100" data-animation-duration="1000" data-md-order="1">
                    <img src={card.image ? 'https://solution-0003.panama.kz' + card.image : img} alt="banner"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img;
                        }}
                    />
                    <div className="banner-layer banner-layer-middle">
                        <h3 style={{color: card.banner_font_color}} dangerouslySetInnerHTML={{__html: card.title}}></h3>
                        {card.description && <div className='description' style={{color: card.banner_font_color}} dangerouslySetInnerHTML={{__html: card.description}}></div>}
                        {card.link && <NavLink to={card.link} className="btn btn-block btn-dark btn-black">{card.subtitle}</NavLink>}
                    </div>
                </div>
            )}
            <div className="grid-col-sizer"></div>
        </div>
    </div>
  )
}

export default AdvertisingBanners