import React from 'react'
import { NavLink } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import img from "../../images/placeholder.png";

const ProjectCard = ({card}) => {
    const {lang} = useLangContext();

  return (
    <div className="col-md-4 col-sm-6 service-card">
        <div className="info-box-img">
            <NavLink to={`/${lang}/projects/${card.slug}`}>
                <img src={card.image ? 'https://solution-0003.panama.kz' + card.image : img} alt="info-box-image"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img;
                    }}
                />
            </NavLink>
            <div className="info-box-content">
                <h4><NavLink to={`/${lang}/projects/${card.slug}`}>{card.title}</NavLink></h4>
                {card.subtitle && <p className="info-desc">{card.subtitle}</p>}
                {card.description && <p dangerouslySetInnerHTML={{__html: card.description}}></p>}
            </div>
        </div>
    </div>
  )
}

export default ProjectCard