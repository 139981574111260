import React, { useState } from 'react'
import AuthServices from '../../services/general/authServices';
import { useLangContext } from '../../i18n/ProvideLang';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';

const ResetPasswordModal = ({show, setShow, email}) => {
    const handleClose = () => setShow(false);
    const {localizationsItems} = useLangContext();
    const [loading, setLoading] = useState(false);

    const {handleSubmit, register} = useForm();

    const onSubmit = async (data) => {
        setLoading(true)
        const newData = {
            email: email,
            ...data
        }

        const res = await AuthServices.checkCode(newData);

        if (res.statusCode === 200) {
            setShow(false);
        }
        setLoading(false)
    }

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {loading &&
                <div className='form-loading'>
                    <div className="isLoading"></div>
                </div>
            }
            <form className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12">
                    <h3>{localizationsItems?.restore_password}</h3>
                    <label>
                        {localizationsItems?.enter_code}
                        <span className="required">*</span>
                    </label>
                    <input type="text" {...register("code")} className="form-input form-wide" required />
                    <div className="text-right mt-2">
                        <button type="submit" className="btn btn-sm mr-2">{localizationsItems?.cancel}</button>
                        <button type="submit" className="btn btn-dark btn-sm">{localizationsItems?.rest_pas}</button>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
}

export default ResetPasswordModal