import React from 'react'

const PageTopNav = () => {
  return (
    <nav aria-label="breadcrumb" className="breadcrumb-nav">
        <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="demo2.html"><i className="icon-home"></i></a></li>
            <li className="breadcrumb-item"><a href="#">Мужчины</a></li>
            <li className="breadcrumb-item active" aria-current="page">Аксессуары</li>
        </ol>
    </nav>
  )
}

export default PageTopNav