import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const HeaderSearch = () => {
    const [term, setTerm] = useState('');

    const navigate = useNavigate();

    const {localizationsItems, lang} = useLangContext();
    
    const searchOn = () => {
        navigate(`/${lang}/catalog?term=${term}`);
    }

    const dynamicSearch = (e) => {
        setTerm(e.target.value);
    }

  return (
    <div className="header-icon mb-0 header-search header-search-inline header-search-category w-lg-max pl-3 pr-1">
        <a href="#" className="search-toggle" role="button" title="Search"><i className="icon-search-3"></i></a>
        <div>
            <div className="header-search-wrapper">
                <input type="search" className="form-control bg-white" name="q" id="qq" value={term} onChange={(e) => dynamicSearch(e)} placeholder={localizationsItems?.search} required/>
                <button className="btn icon-magnifier pb-1 bg-white" onClick={() => searchOn()} title="Search"></button>
            </div>
        </div>
    </div>
  )
}

export default HeaderSearch