import React, { useEffect, useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang'
import Utils from '../../services/utils';
import { NavLink } from 'react-router-dom';
import CatalogServices from '../../services/general/catalogServices';
import { useBasketContext } from '../../hooks/ProvideBasket';
import img from "../../images/placeholder.png";

const CartTr = ({product, uid, fetchItems}) => {
    const [count, setCount] = useState(product.count);

    const [total, setTotal] = useState();

    const {localizationsItems, lang} = useLangContext();

    const {changeCountBasket} = useBasketContext();

    useEffect(() => {
        if(product){
            setTotal(Number(product.price) * Number(count))
        }
    }, [product, count])

    const changeCount = async (e) => {
        const data = {
            uid: uid,
            product_id: product.id,
            count: e.target.value
        }

        const res = await CatalogServices.cart.update(data);

        if(res.statusCode === 200){
            setCount(e.target.value)
        }
    }

    const plusCount = async () => {
        const data = {
            uid: uid,
            product_id: product.id,
            count: count+1
        }

        const res = await CatalogServices.cart.update(data);

        if(res.statusCode === 200){
            setCount(count+1)
        }
    }

    const minusCount = async () => {
        if(count > 1){
            const data = {
                uid: uid,
                product_id: product.id,
                count: count-1
            }
    
            const res = await CatalogServices.cart.update(data);
    
            if(res.statusCode === 200){
                setCount(count-1)
            }
        }
    }

    const deleteItem = async () => {
        const data = {
            uid: uid,
            product_id: product.id
        }

        const res = await CatalogServices.cart.remove(data);

        if(res.statusCode === 200){
            fetchItems()
            changeCountBasket(Math.random().toString(16))
        }
    }

  return (
    <tr className="product-row">
        <td>
            <figure className="product-image-container">
                <NavLink to={`/${lang}/products/${product.slug}`} className="product-image">
                    <img src={product.image ? 'https://solution-0003.panama.kz' + product.image : img} alt="product"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img;
                        }}
                    />
                </NavLink>

                <div className="btn-remove icon-cancel" title="Remove Product" onClick={deleteItem}></div>
            </figure>
        </td>
        <td className="product-col">
            <h5 className="product-title">
                <NavLink to={`/${lang}/products/${product.slug}`}>{product.title}</NavLink>
            </h5>
        </td>
        <td>
            <span className='mob-td'>{localizationsItems?.product_price}: </span>
            {Utils.numberWithSpaces(product.price)} {localizationsItems?.currency}
        </td>
        <td>
            <span className='mob-td'>{localizationsItems?.product_count}: </span>
            <div className="product-single-qty">
                <input className="horizontal-quantity form-control" type="number" value={count} onChange={e => changeCount(e)}/>
                <div className="input-number__add" onClick={plusCount}></div>
                <div className="input-number__sub" onClick={minusCount}></div>
            </div>
        </td>
        <td className="text-right"><span className="subtotal-price">
            <span className='mob-td'>{localizationsItems?.total}: </span>
            {Utils.numberWithSpaces(total)} {localizationsItems?.currency}</span>
        </td>
    </tr>
  )
}

export default CartTr