import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import CatalogServices from '../../services/general/catalogServices'
import MiniProductCard from '../simple/MiniProductCard'
import { useLangContext } from '../../i18n/ProvideLang'

const ProductsMix = () => {
    const {localizationsItems} = useLangContext();

    const {
        items,
    } = useFetchItems(CatalogServices.products.index, `type=sale`)

    const {
        items: relateds,
    } = useFetchItems(CatalogServices.products.index, `type=related`)

    const {
        items:populars,
    } = useFetchItems(CatalogServices.products.index, `type=popular`)

    const {
        items:bestsellers,
    } = useFetchItems(CatalogServices.products.index, `type=bestsellers`)

  return (
    <div className="product-widgets-container row pb-2">
        <div className="col-lg-3 col-sm-6 pb-5 pb-md-0">
            <h4 className="section-sub-title">{localizationsItems?.related_products}</h4>
            {relateds?.items?.slice(0,3).map((card, index) =>
                <MiniProductCard card={card} key={index}/>
            )}
        </div>
        <div className="col-lg-3 col-sm-6 pb-5 pb-md-0">
            <h4 className="section-sub-title">{localizationsItems?.popular_products}</h4>
            {populars?.items?.slice(0,3).map((card, index) =>
                <MiniProductCard card={card} key={index}/>
            )}
        </div>
        <div className="col-lg-3 col-sm-6 pb-5 pb-md-0">
            <h4 className="section-sub-title">{localizationsItems?.new_products}</h4>
            {items?.items?.slice(0,3).map((card, index) =>
                <MiniProductCard card={card} key={index}/>
            )}
        </div>

        <div className="col-lg-3 col-sm-6 pb-5 pb-md-0">
            <h4 className="section-sub-title">{localizationsItems?.hight_rating}</h4>
            {bestsellers?.items?.slice(0,3).map((card, index) =>
                <MiniProductCard card={card} key={index}/>
            )}
        </div>
    </div>
  )
}

export default ProductsMix