import React from 'react';
import Fancybox from './FancyBox';
import img from "../../images/placeholder.png";

const Gallery = ({elem}) => {
  return (
    <div className='mt-8'>
        <h3 className="text-center">{elem.title}</h3>
        <p className='text-center mx-auto mb-3' dangerouslySetInnerHTML={{__html: elem.description}}></p>
        <Fancybox className="project-area bg-secondary"
			options={{
				Carousel: {
				infinite: false,
				},
			}}
		>
			<div className="row">
					{elem.elements?.map((card, index) =>
						<a data-fancybox="gallery" href={'https://solution-0003.panama.kz' + card} className="col-sm-6 col-md-4 col-xl-3 filtr-item pd0" key={index}>
							<div className="project-item">
								<div className="project-image">
									<img src={card ? 'https://solution-0003.panama.kz' + card : img} alt="" 
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img;
                                        }}
                                    />
								</div>
							</div>
						</a>
					)}
			</div>
		</Fancybox>
    </div>
  )
}

export default Gallery