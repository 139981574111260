import {useState} from 'react';

const useBasket = () => {
    const [basketCount, setBasketCount] = useState();

    const changeCountBasket = (count) => {
        setBasketCount(count)
    }

    return {
        basketCount,
        changeCountBasket
    };
};

export default useBasket;