import React, { useRef } from 'react'
import useOnScreen from '../../hooks/useOnScreen.hook'
import CountUp from 'react-countup';

const Features = ({elem}) => {
    const ref = useRef(null)
    const isVisible = useOnScreen(ref)

  return (
    <div className="counters-section bg-gray">
        <div className="container">
            <div className="row" ref={ref}>
                {elem?.elements?.map((card, index) =>
                    <div className="col-6 col-md-4 count-container" key={index}>
                        {card.counter ? 
                            <div className="count-wrapper">
                                {isVisible ? <CountUp start={0} end={card.counter} duration={2.75}/> : <>00</>}{card.title}
                            </div>
                        :
                        <div className="count-wrapper" dangerouslySetInnerHTML={{ __html: elem.title}}></div>
                        }
                        <h4 className="count-title">{card.subtitle}</h4>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default Features