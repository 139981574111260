import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'
import Utils from '../../services/utils';
import CartTr from '../simple/CartTr';

const CartTable = ({items, uid, fetchItems}) => {
    const {lang, localizationsItems} = useLangContext();

  return (
    <div className="cart-table-container">
        <table className="table table-cart">
            <thead>
                <tr>
                    <th className="thumbnail-col"></th>
                    <th className="product-col">{localizationsItems?.product_name}</th>
                    <th className="price-col">{localizationsItems?.product_price}</th>
                    <th className="qty-col">{localizationsItems?.product_count}</th>
                    <th className="text-right">{localizationsItems?.subtotal}</th>
                </tr>
            </thead>
            <tbody>
                {items?.products.map((card, index) =>
                    <CartTr product={card} key={index} uid={uid} fetchItems={fetchItems}/>
                )}
            </tbody>
        </table>
    </div>
  )
}

export default CartTable