import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'

const PageTop = ({item, subLink, subTitle}) => {
    const {lang} = useLangContext();
    
  return (
    <nav aria-label="breadcrumb" className="breadcrumb-nav">
        <div className="container">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={`/${lang}`}><i className="icon-home"></i></NavLink></li>
                {subLink && <li className="breadcrumb-item"><NavLink to={`${subLink}`}>{subTitle}</NavLink></li>}
                <li className="breadcrumb-item active" aria-current="page">{item?.title}</li>
            </ol>
        </div>
    </nav>
  )
}

export default PageTop