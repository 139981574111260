import React from 'react'

const SampleContainer = ({item}) => {
  return (
    <div className="about-section">
        <div className="container">
            <h2 className="subtitle">{item.title}</h2>
            <p dangerouslySetInnerHTML={{__html: item.description}}></p>
        </div>
    </div>
  )
}

export default SampleContainer