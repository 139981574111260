import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import CatalogServices from '../../services/general/catalogServices'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'

const ProductCategories = () => {
    const {lang, localizationsItems} = useLangContext();

    const {
        items
    } = useFetchItems(CatalogServices.categories.index)

  return (
    <div className="col-lg-3 mb-2 appear-animate" data-animation-name="fadeInRightShorter" data-animation-delay="200" data-animation-duration="1000">
        <div className="side-menu-wrapper">
            <h2 className="side-menu-title ls-10">{localizationsItems?.best_categories}</h2>

            <div className="side-menu-body mb-2 px-3 mx-3">
                <ul className="side-menu pb-1">
                    {items?.map((card, index) =>
                        <li key={index}>
                            <NavLink to={`/${lang}/catalog?categories[]=${card.slug}`}>
                                {card.image && <img src={'https://solution-0003.panama.kz' + card.image} alt={card.title} className='category-img'/>}
                                {card.title}
                            </NavLink>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    </div>
  )
}

export default ProductCategories